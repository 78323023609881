import React from "react";
import { withRouter, Route, Switch, Redirect, Router, useHistory } from "react-router-dom";
import Login from "../Pages/Login";
import ProtectedRoute from "./protected-route";
import TicketManagment from "../Pages/Ticket-Managment";
import Users from "../Pages/Users";
import NoAPiKey from "../Pages/NoApiKey";


const Routes: React.FC = () => {
  const history = useHistory();
  const token = localStorage.getItem("token")
  return (
    <Router history={history}>
      <Switch>
        <ProtectedRoute
          component={TicketManagment}
          path="/admin/ticket-support"
          roles={["ADMIN", "SUPPORT"]}
        />
        <ProtectedRoute
          component={Users}
          path="/admin/users"
          roles={["ADMIN", "SUPPORT"]}
        />
        <Route
          exact
          path="/auth/login"
          render={(props: any) => <Login {...props} />}
        />
        <Route 
          path="/admin/tickets"
          component={TicketManagment}
        />
        <Route 
          path="/invalid/apiKey"
          component={NoAPiKey}
        />
        <Route
          exact
          path="/"
          render={() => {
            if (token) {
              return <Redirect to="/admin/ticket-support" />;
            }
            return <Redirect to="/auth/login" />;
          }}
        />
        <Redirect to="/auth/login" />
      </Switch>
    </Router>
  )
}

export default withRouter(Routes);
