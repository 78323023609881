import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import Button from "../Button";
import { CommentProps, commentsState, loggedUserState, otherUsersState } from "../../utils/atoms";
import DraftText from "../DraftText";
import { EditorState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import i18n from "../../i18n";
import { handleComment } from "../../services/PostRequests/comments/createComments";
import { getComments } from "../../services/GetRequests/getComments";
import moment from "moment";


const Comments: React.FC<{ ticketId: number }> = ({ ticketId }) => {
  const [comments, setComments] = useRecoilState(commentsState);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [clickComentInput, setClickComentInput] = useState(false);
  const [commentData, setCommentData] = useState([]);
  const otherUser = useRecoilValue(otherUsersState);
  const currentUser = useRecoilValue(loggedUserState)

  const onEditorStateChange = (editorState: EditorState) => {
    setEditorState(editorState);
  };

  const addComment = async () => {
    const contentState = editorState.getCurrentContent();
    const editorText = contentState.getPlainText();

    if (!editorText.trim()) {
      return;
    }

    const newComment: CommentProps = {
      ticketId: ticketId,
      comment: editorText,
    };
    let customOptions = {};
      if(otherUser?.id) {
        newComment.createdBy = otherUser?.sub;
        customOptions = {
          headers: {
            "ROLE": otherUser?.role,
          }
      }
      }
    setComments([...comments, newComment]);
    setEditorState(EditorState.createEmpty());
    await handleComment({ values: newComment, formik: null,customOptions });
    fetchData();
  };
  const fetchData = async () => {
    const data = await getComments(ticketId);
    setCommentData(data);
  };

  useEffect(() => {
    fetchData();
  }, [ticketId]);

  return (
    <div>
      <h4 className="comments-title">{i18n.t("Comments") as string}</h4>
      <div>
        {commentData?.map((comment: any) => (
          <div key={comment.id} className="comment">
            <div className="user__table__initials">
              <span>{comment?.createdBy?.charAt(0)}</span>
            </div>
            <div className="user__table__initials-details">
              <p>
                {comment.createdBy} -{" "}
                {moment(comment?.createdAt).utc().format("YYYY-MM-DD HH:mm")}
              </p>
              <h4>{comment.comment}</h4>
            </div>
          </div>
        ))
        }
      </div>
      <div className="commentDivider">
        <div className="user__table__initials">
          <span>{otherUser?.id ? otherUser?.sub?.charAt(0) : currentUser?.email.charAt(0)}</span>
        </div>
        <div className="commentInputContainer">
          {!clickComentInput && (
            <input
              onClick={() => setClickComentInput(true)}
              placeholder={i18n.t("AddComment")}
              className="edit-ticket-managment"
            />
          )}
          {clickComentInput && (
            <div className="comment-editor">
              <DraftText
                value={editorState}
                onEditorStateChange={onEditorStateChange}
                wrapClassName="demo-wrapper"
                options={["inline", "emoji"]}
                editClassName="demo-editor"
                contentDraft="draft-content"
              />
              <Button
                style={{
                  position: "absolute",
                  top: 146,
                  right: 4,
                  left: "auto",
                }}
                handleChange={addComment}
                height="46px"
                width="20%"
                text={i18n.t("Comment")}
                customClassName="comment-button"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Comments;
