import React from "react";
import { withRouter } from "react-router-dom";

function TableCustomColumn(props: any) {
  const { row } = props;

  return (
    <div className="user__table__photo__and__name">
      {row.imageUrl ? (
        <img src={row.imgUrl} alt="" />
      ) : (
        <div className="user__table__initials">
          <span>{row?.firstName?.charAt(0)}</span>
        </div>
      )}
      <p className="user__username">{row?.firstName}</p>
    </div>
  );
}

export default withRouter(TableCustomColumn);
