import * as yup from "yup";
import i18n from "../../i18n";


export const userRoles = [
    {
        label: i18n.t("Support"),
        value: "SUPPORT",
    },
    {
        label: i18n.t("Admin"),
        value: "ADMIN",
    },
];
export const statuses = [
    {
        label: i18n.t("Active"),
        value: "ACTIVE",
    },
    {
        label: i18n.t("Inactive"),
        value: "INACTIVE",
    },
    {
        label: i18n.t("Deleted"),
        value: "DELETED",
    },
];

export const validationSchema = yup.object().shape({
    firstName: yup.string()
        .required('First name is required'),
    lastName: yup.string()
        .required('Last name is required'),
    password: yup.string()
        .min(6, 'Password must be at least 6 characters')
        .required('Password is required'),
    email: yup.string()
        .email('Invalid email')
        .required('Email is required'),
    phoneNumber: yup.string()
        .required('Phone number is required'),
    role: yup
        .string()
        .required('User roles are required'),
    categoryTicket: yup
        .array().min(1)
        .required("Category is required"),
    subCategoryTicket: yup
        .array().min(1)
        .required("Subcategory is required"),
    // imageUrl: yup
    //     .mixed()
    //     .required("File is required")
    //     .test("filesize", "file size is too large", (value: FileList | any) => {
    //         if (value && "length" in value) {
    //             for (let i = 0; i < value.length; i++) {
    //                 const file = value[i] as File;
    //                 if (file.size > 5242880) {
    //                     return false;
    //                 }
    //             }
    //         }
    //         return true;
    //     }),
});