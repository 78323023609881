import Autocomplete from "@material-ui/lab/Autocomplete";
import { ToggleAccordionIcon2 } from "../../assets/svgComponents";
import TextField from "@material-ui/core/TextField";

const AutoCompleteComponent: any = (props: any) => {
    const { formik, label, options, name, renderCustomOptions = null, autoClassName = "autocomplete", textFieldPlaceHolder, disable = false, hideEndAdornment, multiple = false } = props;
    const { setFieldValue, values, errors, touched } = formik;
    return (
        <>
            <div className="createTicket__container--category">
                <label htmlFor="selectStatus">
                    {label}
                </label>
                <Autocomplete
                    popupIcon={<ToggleAccordionIcon2 stroke="#979797" />}
                    disableClearable={false}
                    className={autoClassName}
                    disableCloseOnSelect={false}
                    filterSelectedOptions={true}
                    disabled={disable}
                    getOptionLabel={(option: any) => option.label}
                    getOptionSelected={(option: any, value: any) =>
                        option?.value === value?.value
                    }
                    multiple={multiple}
                    onChange={(event, option) => {
                        if (multiple) {
                            setFieldValue(name, option?.map((opt: any) => opt.value))
                        } else {
                            if (option) {
                                setFieldValue(name, option.value);
                            } else {
                                setFieldValue(name, null);
                            }
                        }
                    }}
                    options={options}
                    defaultValue={options?.find(
                        (element: any) => element?.value === values[name]
                    )}
                    renderOption={renderCustomOptions && renderCustomOptions}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            className="text-field"
                            id={name}
                            name={name}
                            placeholder={textFieldPlaceHolder}
                            variant="outlined"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: hideEndAdornment ? null : params.InputProps.endAdornment
                            }}
                        />
                    )}
                />
                {errors?.[name] &&
                    touched?.[name] &&
                    typeof errors?.[name] === "string" && (
                        <span className="errorClass">*{errors?.[name]}</span>
                    )}
            </div>
        </>
    )
}

export default AutoCompleteComponent;