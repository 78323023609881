export const ENDPOINT = {

    //Post
    LOGIN: "/auth",
    CREATE_TICKET: "/ticket/create",
    CREATE_USER: "/user/create",
    CREATE_COMMENT:"/comment/create",
    DELETE_TICKET: "/ticket/delete/:id",
    
    //PUT
    EDIT_TICKET:"ticket/update/:id",
    EDIT_USER: "/user/edit/:id",


    //Get
    GET_CATEGORY_BYID: "/category/get/:id",
    GET_ALL_USERS: "/user/all?page=:page&size=:size&search=:search",
    GET_ALL_TICKETS: "/ticket/all?page=:page&size=:size&search=:search",
    GET_LOGGED_USER: "/user/me",
    GET_ALL_COMMENT: "/comment/getComments/:id",
    GET_SUBCATEGORIES: "/subCategory/all",
    GET_CATEGORIES: "/category/all",
    VALIDATE_API_KEY: "/tenant/validate",
}