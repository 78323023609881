import axios from "axios";
import { ENDPOINT } from "../../utils/endpoint";

export const getComments = async (id: any) => {
    try {
        const response = await axios.get(ENDPOINT.GET_ALL_COMMENT.replace(":id", id));
        return response.data;
    } catch (error) {
        console.error('Error fetching categories:', error);
        throw error;
    }
};