import {
  ToastErrorComponent,
  ToastSuccesComponent,
} from "../../../components/ToastComponent/ToastComponent";
import { CommentFunctionProps } from "../../../types";
import axios from "../../../utils/axios";
import { ENDPOINT } from "../../../utils/endpoint";

export const handleComment = async ({
  values,
  formik,
  customOptions
}: CommentFunctionProps) => {
  await axios
    .post(ENDPOINT.CREATE_COMMENT, { ...values },customOptions)
    .then((res: any) => {
      ToastSuccesComponent("Comment Created Successfully");
    })
    .catch((err: any) => {
      formik.setSubmitting(false);
      if (err.response?.status === 401) {
        ToastErrorComponent("Something went wrong");
      }
    });
};
