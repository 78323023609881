import React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { getCurrentUser } from "../utils/getCurrentUser";

interface ProtectedRouteProps extends RouteProps {
  roles?: string[];
  component?: React.ComponentType<any>;
  render?: any;
  show?: boolean;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  roles,
  component: Component,
  render,
  show = true,
  ...rest
}) => {
  
  const user: any = getCurrentUser();
  const token = localStorage.getItem('token')

  if (!roles?.includes(user?.authorities?.[0].authority)) {
    return <Redirect to="/auth/login" />;
  }

  return (
    <Route
      {...rest}
      render={(props: any) => {
        if (!user && !token) {
          return <Redirect to="/auth/login" />;
        }
        if (user) {
          if (roles?.includes(user?.authorities?.[0]?.authority) && show && token) {
            return Component ? (
              <Component {...props} {...rest} />
            ) : (
              render(props)
            );
          }
          if (user.authorities?.[0]?.authority === "ADMIN" || user.authorities?.[0]?.authority === "SUPPORT") {
            return <Redirect to="/admin/ticket-support" />;
          }
        }
      }}
    />
  );
};
export default ProtectedRoute;
