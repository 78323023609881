import { Popover, ConfigProvider, Segmented } from "antd";
import AutoCompleteComponent from "../AutoComplete";
import { DatePicker, DatePickerProps } from "antd";
import i18n from "../../i18n";

const Filters = (props: any) => {
    const { filterInputs } = props

    const renderInputComponent = (input: any) => {
        switch (input.inputType) {
            case 'dropdown':
                return (
                    <AutoCompleteComponent
                        formikInput={false}
                        name={input.name}
                        options={input.options}
                        formik={{
                            setFieldValue: input.onChange,
                            values: input.values,
                            touched: {},
                            errors: {},
                        }}
                        label={input.title ? i18n.t(input.title) : ''}
                        textFieldPlaceHolder={`Select ${input.title}`}
                        renderCustomOptions={(option: any) => (
                            <div className="createTicket__container--urgency-renderOption">
                                {option.icon}
                                <span style={{ color: option.color }}>{option.label}</span>
                            </div>
                        )}
                    />
                );
            case 'datePicker':
                return (
                    <div className="createTicket__container--dateContainer-items popover__date">
                        <label>{input.title && i18n.t(input.title) as string}</label>
                        <DatePicker />
                    </div>
                );
            default:
                return <></>;
        }
    };

    return (
        <Popover
            openClassName="test"
            trigger="click"
            content={<div className="filter__popover">
                {filterInputs.map((input: any, index: number) => (
                    <div className="filter__popover" key={index}>
                        {renderInputComponent(input)}
                    </div>
                ))}
            </div>}
            placement={props.placement}
        >
            {props.children}
        </Popover>




    )
}

export default Filters