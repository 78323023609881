import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      Users: "Users",
      Support: "Support",
      Logout: "Logout",
      TicketId: "Ticket ID",
      TicketTitle: "Ticket title",
      Status: "Status",
      CreatedBy: "Created by",
      Date: "Date",
      UrgencyLevel: "Urgency Level",
      Category: "Category",
      Subcategory: "Sub-Category",
      Clients: "Clients",
      Action: "Action",
      DeleteTicket: "Delete Ticket",
      Cancel: "Cancel",
      Remove: "Remove",
      RemoveThisTicket: "Are you sure you want to remove this ticket?",
      HighPriority: "High Priority",
      MediumPriority: "Medium Priority",
      LowPriority: "Low Priority",
      None: "None",
      MobileApp: "Mobile App",
      WebApp: "Web App",
      BillingError: "Billing Error",
      TechnicalError: "Technical Error",
      CreateNewTicket: "Create new ticket",
      CreateNewUser: "Create new user",
      Title: "Title",
      Description: "Description",
      Urgency: "Urgency",
      Upload: "Upload",
      DragDataHere: "Drag data here or",
      UploadedFiles: "Uploaded Files",
      DateOfFix: "Date of fix",
      Create: "Create",
      Tenant: "Tenant",
      SentBy: "Sent by",
      SentDate: "Sent date",
      Save: "Save",
      Pending: "Pending",
      Finished: "Finished",
      Declined: "Declined",
      WorkInProgress: "Work in Progress",
      Comment: "Comment",
      AddComment: "Add a comment",
      ApiUrl: "API URL",
      ServerInfo: "Server info",
      ServerLocation: "Server Location",
      FirstName: "First Name",
      LastName: "Last Name",
      PhoneNumber: "Phone Number",
      Email: "Email",
      CreateUser: "Create user",
      SP: "SP",
      Employee: "Employee",
      Agency: "Agency",
      AgencySP: "Agency SP",
      BackOffice: "Back-Office",
      ManagementInfo: "Management Info",
      EmployeeShop: "Employee Shop",
      EmployeeTL: "Employee TL",
      EmployeeAB: "Employee AB",
      Agency70: "Agency 70",
      Role: "Role",
      Contracts: "Contracts",
      Locations: "Locations",
      Trainings: "Trainings",
      Chat: "Chat",
      Active: "Active",
      Inactive: "Inactive",
      Deleted: "Deleted",
      Password: "Password",
      Comments: "Comments",
      EditTicket: "Edit Ticket",
      Confirm: "Confirm",
      NoData: "No Data",
      EditUser: "Edit User",
      TitleIsRequired: "Title is required",
      DescriptionIsRequired: "Description is require",
      CategoryIsRequired: "Category is required",
      SubCategoryIsRequired: "Subcategory is required",
      UrgencyIsRequired: "Urgency is required",
      DateOfFixIsRequired: "Selecting date is required",
      UpdatedBy: "Updated By",
      apiKeyMissing: "API Key Missing",
			apiKeyMissingInfo:
				"Oops! It looks like you haven't provided an API key. To continue using this feature, please add your API key in the marketplace.",
    },
  },
  de: {
    translation: {
      Users: "Benutzer",
      Support: "Unterstützung",
      Logout: "Ausloggen",
      TicketId: "Ticketnummer",
      TicketTitle: "Tickettitel",
      Status: "Status",
      CreatedBy: "Erstellt von",
      Date: "Datum",
      UrgencyLevel: "Dringlichkeitsstufe",
      Category: "Kategorie",
      Subcategory: "Unterkategorie",
      Clients: "Kunden",
      Action: "Aktion",
      DeleteTicket: "Ticket löschen",
      Cancel: "Stornieren",
      Remove: "Entfernen",
      RemoveThisTicket: "Möchten Sie dieses Ticket wirklich entfernen?",
      HighPriority: "Hohe Priorität",
      MediumPriority: "Mittlere Priorität",
      LowPriority: "Niedrige Priorität",
      None: "Keiner",
      MobileApp: "Mobile Anwendung",
      WebApp: "Webanwendung",
      BillingError: "Abrechnungsfehler",
      TechnicalError: "Technischer Fehler",
      CreateNewTicket: "Neues Ticket erstellen",
      Title: "Titel",
      Description: "Beschreibung",
      Urgency: "Dringlichkeit",
      Upload: "Hochladen",
      DragDataHere: "Ziehen Sie Daten hierher oder",
      UploadedFiles: "Hochgeladene Dateien",
      DateOfFix: "Datum der Korrektur",
      Create: "Erstellen",
      Tenant: "Mieterin:",
      SentBy: "Gesendet von",
      SentDate: "Sendedatum",
      Save: "Speichern",
      Pending: "Ausstehend",
      Finished: "Fertig",
      Declined: "Abgelehnt",
      WorkInProgress: "Arbeit in Bearbeitung",
      Comment: "Kommentar",
      AddComment: "Einen Kommentar hinzufügen",
      ApiUrl: "API-URL",
      ServerInfo: "Serverinformationen",
      ServerLocation: "Serverstandort",
      FirstName: "Vorname",
      LastName: "Nachname",
      PhoneNumber: "Telefonnummer",
      Email: "E-Mail",
      CreateNewUser: "Neuen Benutzer erstellen",
      SP: "SP",
      Employee: "Mitarbeiter",
      Agency: "Agentur",
      AgencySP: "Agentur SP",
      BackOffice: "Back-Office",
      ManagementInfo: "Management Info",
      EmployeeShop: "Mitarbeitershop",
      EmployeeTL: "Mitarbeiter TL",
      EmployeeAB: "Mitarbeiter AB",
      Agency70: "Agentur 70",
      CreateUser: "Benutzer erstellen",
      Contracts: "Verträge",
      Locations: "Standorte",
      Trainings: "Schulungen",
      Chat: "Plaudern",
      Active: "Aktiv",
      Inactive: "Inaktiv",
      Deleted: "Gelöscht",
      Password: "Passwort",
      Comments: "Kommentare",
      EditTicket: "Ticket bearbeiten",
      Confirm: "Bestätigen",
      NoData: "Keine Daten",
      EditUser: "Benutzer Bearbeiten",
      TitleIsRequired: "Titel ist erforderlich",
      DescriptionIsRequired: "Beschreibung ist erforderlich", 
      CategoryIsRequired: "Kategorie ist erforderlich",
      SubCategoryIsRequired: "Unterkategorie ist erforderlich",
      UrgencyIsRequired: "Dringlichkeit ist erforderlich",
      DateOfFixIsRequired: "Datumsauswahl ist erforderlich",
      UpdatedBy: "Aktualisiert von",
      apiKeyMissing: "API-Schlüssel fehlt",
			apiKeyMissingInfo:
				"Ups! Es sieht so aus, als hätten Sie keinen API-Schlüssel angegeben. Um diese Funktion weiterhin zu nutzen, fügen Sie bitte Ihren API-Schlüssel im Marktplatz hinzu.",
    },
  },
};

const _language = localStorage.getItem("language");
export const langVar = localStorage.getItem("language");

const queryParams = new URLSearchParams(window.location.search);
const paramLang = queryParams.get("lang");

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: "de",
    lng: paramLang || _language || "de", // Use "de" as a default language if _language is null
    interpolation: {
      escapeValue: false, // react already safeguards against xss
    },
  });

export default i18n;
