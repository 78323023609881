import { getCurrentUser } from "../../utils/getCurrentUser";
import { useLocation, useHistory } from "react-router-dom";
import { DrawerItemProps } from "../../types";
import { handleLogout } from "../../services/PostRequests/auth/auth";

const DrawerItems = (props: DrawerItemProps) => {
  const user: any = getCurrentUser();
  const location = useLocation();
  const history = useHistory();
  const { element, collapse } = props;

  const handleNavigation = (element: any) => {
    // if (element.link === "/auth/logout") {
    //   handleLogout(history)
    // } else {
    history.push(element.link);
    // }
  };

  return (
    <>
      {element?.roles?.includes(user?.role) && (
        <div
          className={`drawer-items_ ${location?.pathname.includes(element.drawerName) && "active"
            }`}
          onClick={() => handleNavigation(element)}
        >
          <div
            style={{
              width: !collapse ? "100%" : "",
              justifyContent: !collapse ? "center" : "flex-start",
            }}
            className="drawer-items__wrapper"
          >
            <div className="drawer-items__icon">{element?.icon}</div>
            {collapse && (
              <div className="drawer-items__text">
                <span>{element.name}</span>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default DrawerItems;
