import {
  UsersIcon,
  LogoutLogo,
  SupportIcon,
} from "../assets/svgComponents";
import i18n from "../i18n";

export const drawerItems = [
  {
    icon: <SupportIcon />,
    name: i18n.t("Support"),
    link: "/admin/ticket-support",
    drawerName: "ticket-support",
    roles: ["ADMIN", "SUPPORT"],
  },
  {
    icon: <UsersIcon />,
    name: i18n.t("Users"),
    link: "/admin/users",
    drawerName: "users",
    roles: ["ADMIN", "SUPPORT"],
  },
];

export const bottomItems = [
  {
    icon: <LogoutLogo />,
    name: i18n.t("Logout"),
    link: "/auth/logout",
    drawerName: "logout",
    roles: ["SUPPORT", "ADMIN"],
  },
];
