import { getCurrentUser } from "../../utils/getCurrentUser";
import DrawerItems from "./item";
import { drawerItems, bottomItems } from "../../utils/drawerItems";
import rightArrowIcon from "../../assets/img/collapseIcon.svg";
import { useHistory, useLocation } from "react-router-dom";
import { DrawerItem, DrawerProps, DrawerItemProps } from "../../types";
import { LogoutLogo } from "../../assets/svgComponents";
import { handleLogout } from "../../services/PostRequests/auth/auth";

const Drawer = (props: DrawerProps) => {
  const { setCollapse, collapse, newClass } = props;
  const drawerItemHeight = 45; // this const should be equal to drawer item height set on sccs
  const tabHeight = drawerItemHeight / 2 / 2;
  const user: any = getCurrentUser();
  const history = useHistory();
  const location = useLocation();
  const filterDrawerItems = drawerItems.filter((elem: any) =>
    elem?.roles?.includes(user?.authorities?.[0]?.authority)
  );
  const findActiveTabIndex = filterDrawerItems.findIndex((elem: any) =>
    location?.pathname.includes(elem.drawerName)
  );
  const y =
    findActiveTabIndex === -1 ? 0 : findActiveTabIndex * drawerItemHeight;
  const filterBottomItems = bottomItems.filter((elem) =>
    elem?.roles?.includes(user?.authorities?.[0]?.authority)
  );
  const findActiveBottomTabIndex = filterBottomItems.findIndex((elem: any) =>
    location?.pathname.includes(elem.drawerName)
  );

  const yBottom =
    findActiveBottomTabIndex === -1
      ? 0
      : findActiveBottomTabIndex * drawerItemHeight;

  return (
    <div
      style={{
        minWidth: !collapse ? "100px" : "200px",
        width: !collapse ? "100px" : "200px",
      }}
      className={`drawer ${newClass || ""}`}
    >
      <div className="drawer__imgcontent">
        {user?.imgUrl ? (
          <img
            className="drawer__img"
            src={user || user?.imgUrl}
            alt="user img"
          />
        ) : (
          <div className="drawer__img char">
            <span>{user?.firstName ? user?.firstName?.charAt(0) : user?.sub?.charAt(0)}</span>
            <span>{user?.lastName?.charAt(0)}</span>
          </div>
        )}
      </div>
      <div className={`drawer-items `}>
        {filterDrawerItems.map((element: DrawerItem, index: number) => (
          <DrawerItems collapse={collapse} element={element} key={index} />
        ))}
        <div
          className={`drawer-items__tab ${findActiveTabIndex === -1 && "hide"}`}
          style={{ top: y + tabHeight }}
        ></div>
      </div>
      <div className="collapse-sidebar" onClick={() => setCollapse(!collapse)}>
        <img
          src={rightArrowIcon}
          alt=""
          style={{ transform: collapse ? "rotate(-180deg)" : "rotate(0deg)" }}
        />
      </div>
      {/* <div className="drawer-settings drawer-items">
        {bottomItems.map((element: DrawerItem, index: number) => (
          <DrawerItems collapse={collapse} element={element} key={index} />
        ))}
        <div
          className={`drawer-items__tab ${findActiveBottomTabIndex === -1 && "hide"
            }`}
          style={{ top: yBottom + tabHeight }}
        ></div>
      </div> */}
      <div
        className="user__and__logout"
        style={{ justifyContent: !collapse ? "center" : "space-between" }}
      >
        {collapse && (
          <div className="user__infos">
            {user?.imgUrl ? (
              <img alt="" src={user?.imgUrl} />
            ) : (
              <div className="drawer__img char">
                <span>{user?.firstName ? user?.firstName?.charAt(0) : user?.sub?.charAt(0)}</span>
                <span>{user?.lastName?.charAt(0)}</span>
              </div>
            )}
            <span className="user__infos-name">
              {user?.firstName || <span>{user?.firstName ? user?.firstName?.charAt(0) : user?.sub?.substring(0, 5)}</span>} {user?.lastName}
            </span>
          </div>
        )}
        <div
          className="logout__user"
          onClick={() => handleLogout(history)}
        // style={{ pointerEvents: loading ? "none" : "auto" }}
        >
          <LogoutLogo />
        </div>
      </div>
    </div>
  );
};

export default Drawer;
