import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import ButtonComponent from "../../../components/ButtonComponent";
import ModalComponent from "../../../components/modal";
import { DeleteModalProps } from "../../../types";
import i18n from "../../../i18n";

const DeleteModal: FunctionComponent<DeleteModalProps> = (props) => {
  const { t } = useTranslation();
  const {
    setShowDeleteModal,
    confirm,
    word,
    open,
    handleClose,
    disabled = false,
    modalType = "delete",
    settle = false,
    deleteModal = false,
    draft = false,
    newTitle = "",
  } = props;

  const isDraft = draft ? t("AreYouSureDraft") : t("AreYouSureDeactivate");

  return (
    <ModalComponent open={open} handleClose={handleClose}>
      {deleteModal ? (
        <div className="delete__modal__component">
          <h1>{newTitle || t("AreUSureCalculation")}</h1>
          <div>
            <ButtonComponent
              classNames="cancel__button"
              buttonText={i18n.t("Cancel")}
              onClick={() => setShowDeleteModal(false)}
            />
            <ButtonComponent
              classNames="confirm__button"
              buttonText={i18n.t("Confirm")}
              disabled={disabled}
              onClick={confirm}
            />
          </div>
        </div>
      ) : (
        <div className="delete__modal__component">
          {settle ? (
            <h1> {t("AreYouSureSettle")} </h1>
          ) : (
            <h1>
              {modalType === "delete" ? (
                isDraft
              ) : (
                // t`AreYouSureDeactivate`
                <>
                  {modalType === "permanantDelete"
                    ? t`AreYouSureDelete`
                    : t`AreYouSureActivate`}
                </>
              )}
              {word}?
            </h1>
          )}
          <div>
            <ButtonComponent
              classNames="cancel__button"
              buttonText={t("Cancel")}
              onClick={() => setShowDeleteModal(false)}
            />
            <ButtonComponent
              classNames="confirm__button"
              buttonText={t("Confirm")}
              disabled={disabled}
              onClick={confirm}
            />
          </div>
        </div>
      )}
    </ModalComponent>
  );
};

export default DeleteModal;
