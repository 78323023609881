import {
  ToastErrorComponent,
  ToastSuccesComponent,
} from "../../../components/ToastComponent/ToastComponent";
import { DeleteTicketValueProps } from "../../../types";
import axios from "../../../utils/axios";
import { ENDPOINT } from "../../../utils/endpoint";

export const deleteTicket = async ({ ticketd }: DeleteTicketValueProps) => {
  await axios
    .delete(ENDPOINT.DELETE_TICKET.replace(":id", `${ticketd}`))
    .then((res: any) => {
      ToastSuccesComponent("Ticket Delete Successfully");
    })
    .catch((err: any) => {
      if (err.response?.status === 401) {
        ToastErrorComponent("Something went wrong");
      }
    });
};
