import { HeaderProps } from "../../types";
import Button from "../Button";
import BigSearchBar from "../BigSearchBar";
import plusIconBackground from "../../assets/img/plusIconBackground.svg";
import Filters from "../Filters";
import { useState } from "react";

const Header = (props: HeaderProps) => {
  const { Icon, title, handleSearch, searchValue, isUsers, handleButton, withFilters = true, handleFilters, popoverContent, filterInputs } =
    props;


  return (
    <div className="header-component">
      <div className="dashboard__left__text-header">
        <div className="header-component-wrapper">
          <Icon />
          <span className="header-component-text">{title}</span>
        </div>
      </div>
      <div className="dashboard__left__text-actions">
        <Button
          border="none"
          text={isUsers ? "Create User" : "Create Ticket"}
          smallButtonWithTooltip
          handleChange={handleButton}
          icon={plusIconBackground}
        />
        <BigSearchBar
          handleSearch={handleSearch}
          value={searchValue}
          withFilters={withFilters}
          handleFilters={handleFilters}
          popoverContent={popoverContent}
          filterInputs={filterInputs}
        />
      </div>
    </div>
  );
};

export default Header;
