import { LoginFunctionProps } from "../../../types";
import axios from "../../../utils/axios";
import { ENDPOINT } from "../../../utils/endpoint";

export const handleLogin = ({ values, formik, history }: LoginFunctionProps) => {
    axios
        .post(ENDPOINT.LOGIN, values)
        .then((res: any) => {
            localStorage.setItem("token", res?.data?.token);
            history.push('/admin/ticket-support');
        })
        .catch((err: any) => {
            formik.setSubmitting(false);
            if (err.response?.status === 401) {
                formik.setErrors({
                    password: "Your email or password is incorrect!",
                });
            }
        });
};

export const handleLogout = (history: any) => {
    try {
        localStorage.removeItem("token");
        history.push('/auth/login');
    } catch {
        alert('Error occurred while logging out!');
    }
};