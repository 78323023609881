import { toast } from "react-toastify";
// import i18n from "../../i18n";
import { ToastSuccessIcon } from "../../assets/svgComponents";
import "react-toastify/dist/ReactToastify.css";

export const ToastSuccesComponent = (message: any) =>
    toast.success(message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        icon: <ToastSuccessIcon />,
        style: {
            color: "#6C1EB0",
            fontFamily: "Montserrat",
            fontSize: 14,
            fontWeight: 500,
            boxShadow: "0px 2px 4px 4px rgba(60, 60, 60, 0.2)",
            borderRadius: "8px",
        },
    });

export const ToastErrorComponent = (message: any) =>
    toast.error(message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
            color: "#6C1EB0",
            fontFamily: "Montserrat",
            fontSize: 14,
            fontWeight: 500,
            boxShadow: "0px 2px 4px 4px rgba(60, 60, 60, 0.2)",
            borderRadius: "8px",
        },
    });

export const ToastInfoComponent = (message: any) =>
    toast.info(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
            color: "#6C1EB0",
            fontFamily: "Montserrat",
            fontSize: 14,
            fontWeight: 500,
            boxShadow: "0px 2px 4px 4px rgba(60, 60, 60, 0.2)",
            borderRadius: "8px",
        },
    });

