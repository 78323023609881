import React from "react";
import ModalComponent from "../../../components/modal";
import { EditModalProps } from "../../../types";
import TicketHeaderComponent from "./headerTicketModal";
import i18n from "../../../i18n";
import { useFormik } from "formik";
import Button from "../../../components/Button";
import { urgency } from "../staticData";
import { dataOfStatus } from "../columns";
import AutoCompleteComponent from "../../../components/AutoComplete";
import { DatePicker } from "antd";
import Comments from "../../../components/CommentsComponent";
import TicketInfoComponent from "../../../components/TicketInfoComponent/TicketInfoComponent";
import { editTicket } from "../../../services/PostRequests/tickets/edit";
import { allCategoriesState, allSubCategoriesState, ticketSupportState } from "../../../utils/atoms";
import { useRecoilValue } from "recoil";
import moment from "moment";
import { retrieveParams } from "../../../utils/retrieveParams";

const EditModal: React.FC<EditModalProps> = ({
  showModal,
  handleClose,
  setTickets,
  id,
  ticketData
}: any) => {
  const subCategories = useRecoilValue(allSubCategoriesState);
  const categories = useRecoilValue(allCategoriesState);
  const initialValues = useRecoilValue(ticketSupportState);
  const {apiKey} = retrieveParams()

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: (values) => {
      handleOnSubmit(values);
      handleClose()
    },
  });

  const { handleSubmit, setFieldValue, values } = formik;


  const handleOnSubmit = async (values: any) => {
    const payload = {
      id: values.id,
      description: values.description,
      category: values.categoryTicket,
      subcategory: values.subCategoryTicket,
      urgencyTicket: values?.urgencyTicket,
      ticketStatus: values?.status
    }
    await editTicket({ payload, setTickets, ticketData });
  };
  const createdAt = ticketData?.data?.[0]?.createdAt;
  const formattedDate = moment(createdAt).format('YYYY-MM-DD');

  return (
    <ModalComponent
      open={showModal}
      handleClose={handleClose}
      customClassNames="ticket-modal"
      positionModal="right"
    >
      <div className="ticket__modalContainer">
        <>
          <form onSubmit={handleSubmit}>
            <div className="createTicket__superContainer">
              <TicketHeaderComponent
                handleClose={handleClose}
                title={i18n.t("EditTicket")}
              />
              <div className="ticket">
                <div className="ticket-header">
                  <h2>Ticket - {id}</h2>
                </div>
                <div className="ticket-info">
                  <TicketInfoComponent
                    label={`${i18n.t("Tenant Id")}:`}
                    span={ticketData?.data?.[0]?.tenantId}
                  />
                  <TicketInfoComponent
                    label={`${i18n.t("CreatedAt")}:`}
                    span={formattedDate}
                  />
                  <TicketInfoComponent
                    label={`${i18n.t("CreatedBy")}:`}
                    span={ticketData?.data?.[0]?.updatedBy}
                  />
                </div>
              </div>
              <div className="createTicket__container">
                <AutoCompleteComponent
                  formik={formik}
                  name="status"
                  autoClassName="dropdown__customClass"
                  label={i18n.t("Status") as string}
                  options={dataOfStatus}
                  textFieldPlaceHolder={formik.values.ticketStatus}
                  hideEndAdornment={apiKey}
                  disable={apiKey}
                  renderCustomOptions={(option: any) => (
                    <div
                      className="createTicket__container--urgency-renderOption editticket-status"
                      style={{
                        background: `${option.color}90`,
                        // border: `1px solid ${option.color}`,
                      }}
                    >
                      <div className="editticket-status-icon">
                        {option.icon}
                      </div>
                      <div style={{ color: "white" }}>{option.label}</div>
                    </div>
                  )}
                />

                <div className="createTicket__container--description">
                  <label>{i18n.t("Description") as string}</label>
                  <div
                    className="ticket-description"
                    dangerouslySetInnerHTML={{ __html: values?.description || "<p></p>" }}
                  ></div>
                </div>

                <AutoCompleteComponent
                  formik={formik}
                  name="categoryTicket"
                  autoClassName="dropdown__customClass"
                  label={i18n.t("Category") as string}
                  disable={apiKey}
                  hideEndAdornment={apiKey}
                  options={categories.map((category: any) => ({
                    label: category.label || "",
                    value: category.id || null,
                  }))}
                  textFieldPlaceHolder={'Select Category'}
                />
                <AutoCompleteComponent
                  formik={formik}
                  disable={apiKey}
                  autoClassName="dropdown__customClass"
                  hideEndAdornment={apiKey}
                  name="subCategoryTicket"
                  label={i18n.t("Subcategory") as string}
                  options={subCategories.map((subCategory: any) => ({
                    label: subCategory.label,
                    value: subCategory.id,
                  }))}
                  textFieldPlaceHolder={values.subCategoryTicket}
                />
                <AutoCompleteComponent
                  formik={formik}
                  disable={apiKey}
                  autoClassName="dropdown__customClass"
                  hideEndAdornment={apiKey}
                  name="urgencyTicket"
                  label={i18n.t("Urgency") as string}
                  options={urgency}
                  textFieldPlaceHolder={values.urgencyTicket}
                  renderCustomOptions={(option: any) => (
                    <div className="createTicket__container--urgency-renderOption">
                      {option.icon}
                      <span style={{ color: option.color }}>{option.label}</span>
                    </div>
                  )}
                />

                <div className="createTicket__container--dateContainer">
                  <div className="createTicket__container--dateContainer-items">
                    <label>{i18n.t("DateOfFix") as string}</label>
                    <DatePicker
                      placeholder={moment(values?.createdAt).format("DD.MM.YYYY")}
                      disabled
                    />
                  </div>
                </div>

                {/* <TicketUpload
                  name="dataTicket"
                  setFieldValue={setFieldValue}
                  dataTicket={values?.dataTicket || []}
                  removeImage
                /> */}

                <Comments ticketId={id} />

                {!apiKey && (
                  <Button
                    type="submit"
                    width="100%"
                    text={i18n.t("Save")}
                    customClassName="createTicket__container--createButton-button"
                  />
                )}
              </div>
            </div>
          </form>
        </>
      </div>
    </ModalComponent>
  );
};

export default EditModal;
