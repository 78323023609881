import * as yup from "yup";

export const validationSchema = yup.object().shape({
  email: yup
    .string()
    .lowercase()
    .strict()
    .label("Email")
    .email("EmailIsRequred")
    .required("EnterRefisteredEmail"),
  password: yup
    .string()
    .label("Password")
    .required("Password Required")
    .min(0, "PasswordMustBe"),
});
