import { convertToRaw } from "draft-js";
import { ToastErrorComponent, ToastSuccesComponent } from "../../../components/ToastComponent/ToastComponent";
import { TicketFunctionProps } from "../../../types";
import axios from "../../../utils/axios";
import { ENDPOINT } from "../../../utils/endpoint";
import draftToHtml from "draftjs-to-html";

export const handleTicket = async ({ values, formik, onSuccess, customOptions }: TicketFunctionProps) => {
    // const images = await convertImagesToBase64(values.dataTicket)
    // @ts-ignore
    const editorStateToString = draftToHtml(convertToRaw(values?.description.getCurrentContent()));
    await axios
        // .post(ENDPOINT.CREATE_TICKET, { ...values, description: editorStateToString, dataTicket: images },
        .post(ENDPOINT.CREATE_TICKET, { ...values, description: editorStateToString }, customOptions
        )
        .then((res) => {
            ToastSuccesComponent("Ticket Created Successfully");
            if (onSuccess) onSuccess(res.data)
        })
        .catch((err: any) => {
            formik.setSubmitting(false);
            if (err.response?.status === 401) {
                ToastErrorComponent("Something went wrong")
            }
        });
};

// convert to base64 multiple files
export const convertImagesToBase64 = async (files: any) => {
    const arr: any[] = [];
    await Promise.all(files.map(async (element: any) => {
        const convertedFile = await handleFileRead(element);
        arr.push(convertedFile);
    }));
    return arr;
}

// convert to base64
export const convertBase64 = (file: any) =>
    new Promise((resolve, reject) => {
        if (file) {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                resolve(fileReader.result);
            };
            fileReader.onerror = (error) => {
                reject(error);
            };
        }
    });

// convert to base64 single files
export const handleFileRead = async (event: any) => {
    const file = event;
    const base64 = await convertBase64(file);
    return base64;
};