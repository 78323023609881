import React, { useEffect, useRef, useState } from "react";
import { StatusBoxProps } from "../../types";

const StatusBox = (props: StatusBoxProps) => {
  const { row, statusData, percentage, showText = true } = props;
  const [, setWidth] = useState("");
  const parentRef = useRef<any>(null);
  useEffect(() => {
    if (parentRef.current) {
      const parentWidth = parentRef.current.offsetWidth;
      setWidth(`${parentWidth}px`);
    }
  }, []);
  return (
    <div className="container">
      <div
        className="status-container"
        ref={parentRef}
        style={{
          backgroundColor: `${statusData?.[row]?.color}${percentage && percentage < 100 ? "66" : "bf"
            }`,
          border: `1px solid ${statusData?.[row]?.color}`,
          position: percentage && "relative",
        }}
      >
        <div
          className="status-icon-container"
          style={{ borderRight: `1px solid ${statusData?.[row]?.color}` }}
        >
          {statusData[row]?.icon}
        </div>
        {/* {showText && <span className="status-text">{row}</span>} */}
        {showText && <span className="status-text"> {statusData?.[row]?.label}</span>}
      </div>
    </div>
  );
};

export default StatusBox;
