import { atom } from "recoil";

interface LoginState {
  email: string;
  password: string;
}

interface TicketSupportState {
  title: string;
  description: string | undefined;
  categoryTicket: string;
  subCategoryTicket: string;
  urgencyTicket: string;
  createdAt?: number;
  dateOfFix: number | null;
  ticketStatus: string;
}
export interface UserSupportState {
  id?: number | undefined | null;
  firstName: string;
  lastName: any;
  password: string,
  phoneNumber: string;
  email: string;
  categoryTicket: number[],
  subCategoryTicket: number[],
  urgencyTicket?: string,
  // imageUrl: File | null;
  role: string;
  status: string;
  category?: string[];
  subCategory?: string[];
}

interface TicketModalProps {
  showModal: boolean;
  handleClose: () => void;
}

export interface CommentProps {
  ticketId: number;
  comment: string;
  createdBy?: string;
}

export interface Category {
  id: number;
  label: string;
}
export interface SubCategory {
  id: number;
  label: string;
}
export interface AllUsers {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  role: string;
  status: string;

}
export interface SingleTickets {
  category: string;
  createdBy: string;
  customData: null;
  dataTicket: string[];
  createdAt: number | null;
  description: string;
  id: number;
  subCategory: string;
  title: string;
  updatedAt?: any;
  updatedBy: string;
  urgencyTicket: number;
}

export const loginState = atom<LoginState>({
  key: 'loginState',
  default: {
    email: "",
    password: "",
  },
});

export const ticketSupportState = atom<TicketSupportState>({
  key: "ticketSupportState",
  default: {
    title: "",
    description: undefined,
    categoryTicket: "",
    subCategoryTicket: "",
    urgencyTicket: "",
    dateOfFix: null,
    ticketStatus: "PENDING",
    // category: "",
    // subCategory: "",
  }
})

export const userSupportState = atom<UserSupportState>({
  key: "userSupportState",
  default: {
    id: null,
    firstName: "",
    lastName: "",
    password: "",
    email: "",
    phoneNumber: "",
    categoryTicket: [],
    subCategoryTicket: [],
    urgencyTicket: "",
    // imageUrl: null,
    role: "",
    status: "ACTIVE",
  }
})

export const ticketModal = atom<TicketModalProps>({
  key: "ticketModal",
  default: {
    showModal: false,
    handleClose: () => { }
  }
})

export const commentsState = atom<CommentProps[]>({
  key: 'commentsState',
  default: [],
});

//Get Data
export const allCategoriesState = atom({
  key: 'allCategoriesState ',
  default: [] as Category[],
});

export const allSubCategoriesState = atom<SubCategory[]>({
  key: 'allSubCategoriesState ',
  default: [],
});
export const allUsersState = atom<any>({
  key: 'allUsersState ',
  default: {
    data: [] as AllUsers[],
    page: 1,
    totalPages: 1,
    totalSize: 0,
    size: 30,
  },
});
export const allTicketsState = atom<any>({
  key: 'allTicketsState ',
  default: {
    data: [] as SingleTickets[],
    page: 1,
    totalPages: 1,
    totalSize: 0,
    size: 30,
  },
});
export const loggedUserState = atom<any>({
  key: 'getLoggedUser',
  default: null
})
export const otherUsersState = atom<any>({
  key: 'otherUsersState',
  default: null
})

