import { useFormik } from "formik";
import { validationSchema } from "../staticData";
import InputComponent from "../../../components/InputComponent";
import i18n from "../../../i18n";
import Button from "../../../components/Button";
import UserHeaderComponent from "./headerUserModal";
import { UserSupportState, allCategoriesState, allSubCategoriesState, allUsersState, ticketModal, userSupportState } from "../../../utils/atoms";
import { useRecoilState, useRecoilValue } from "recoil";
import { CreateTicketProps, TicketModalProps } from "../../../types";
import { userRoles } from "../staticData";
import { handleEditUser, handleUser } from "../../../services/PostRequests/users/createUser";
import AutoCompleteComponent from "../../../components/AutoComplete";

function CreateUser(props: CreateTicketProps) {
  const { handleClose } = props;
  const [initialValues] = useRecoilState(userSupportState);
  const categories = useRecoilValue(allCategoriesState);
  const subCategories = useRecoilValue(allSubCategoriesState);
  const [modalState, setModalState] = useRecoilState<TicketModalProps>(ticketModal);
  const [users, setUsers] = useRecoilState(allUsersState);

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values: UserSupportState) => {
      handleOnSubmit(values)
      setModalState(prevState => ({
        ...prevState,
        showModal: false,
      }));
    },
  });

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    handleBlur,
  } = formik;


  const addUserOnState = (data: any) => {
    const foundedCategories = categories?.filter((cat: any) => values?.categoryTicket?.includes(cat?.id));
    const foundedSubCategories = subCategories?.filter((cat: any) => values?.subCategoryTicket?.includes(cat?.id));
     const newUser = {
      ...values,
      id: data.data.userId,
      category: foundedCategories?.map((cat: any) => cat.category),
      subCategory: foundedSubCategories?.map((cat: any) => cat.category),
     }
     setUsers({
      ...users,
      data: [newUser,...users.data]
     });
  };
  const editUserOnState = () => {
    const updatedUsers = users?.data?.map((user: any) => {
      if(user?.id === values?.id) {
        return {
          ...values,
          password: null,
        };
      }
      return user;
    }) 
    setUsers({
      ...users,
      data: updatedUsers
    })
  }

  const handleOnSubmit = (values: UserSupportState) => {
    delete values.urgencyTicket; 
    if(values?.id) {
      handleEditUser({ values, formik, onSuccess: editUserOnState })
    } else {
      handleUser({ values, formik,onSuccess: addUserOnState })
    }
  }

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="createTicket__superContainer">
          <UserHeaderComponent
            handleClose={handleClose}
            title={values.id ? i18n.t("EditUser") : i18n.t("CreateNewUser")}
          />
          <div className="createTicket__containers">
            <div className="createTicket__container">
              <div>
                <div className="createTicket__container--title">
                  <InputComponent
                    label={i18n.t("FirstName") as string}
                    name="firstName"
                    type="text"
                    errorClass="errorClass"
                    errors={errors?.firstName}
                    values={values?.firstName}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    touched={
                      typeof touched?.firstName === "boolean"
                        ? touched?.firstName
                        : undefined
                    }
                    newClass="secondStep__input"
                  />
                  <InputComponent
                    label={i18n.t("LastName") as string}
                    name="lastName"
                    type="text"
                    errorClass="errorClass"
                    errors={errors?.lastName}
                    values={values?.lastName}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    touched={
                      typeof touched?.lastName === "boolean"
                        ? touched?.lastName
                        : undefined
                    }
                    newClass="secondStep__input"
                  />
                  <InputComponent
                    label={i18n.t("Email") as string}
                    name="email"
                    type="email"
                    errorClass="errorClass"
                    errors={errors?.email}
                    values={values?.email}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    touched={
                      typeof touched?.email === "boolean"
                        ? touched?.email
                        : undefined
                    }
                    newClass="secondStep__input"
                  />
                  <InputComponent
                    label={i18n.t("Password") as string}
                    name="password"
                    type="password"
                    errorClass="errorClass"
                    errors={errors?.password}
                    values={values?.password}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    touched={
                      typeof touched?.password === "boolean"
                        ? touched?.password
                        : undefined
                    }
                    newClass="secondStep__input"
                  />
                  <InputComponent
                    label={i18n.t("PhoneNumber") as string}
                    name="phoneNumber"
                    type="number"
                    errorClass="errorClass"
                    errors={errors?.phoneNumber}
                    values={values?.phoneNumber}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    touched={
                      typeof touched?.phoneNumber === "boolean"
                        ? touched?.phoneNumber
                        : undefined
                    }
                    newClass="secondStep__input"
                  />
                  <div className="createTicket__container--dropdown">
                    <AutoCompleteComponent
                      formik={formik}
                      name="categoryTicket"
                      label={i18n.t("Category") as string}
                      options={categories.map((category: any) => ({
                        label: category?.category,
                        value: category?.id,
                      }))}
                      textFieldPlaceHolder={values.id ? values.category : "Select Category"}
                      multiple={true}
                    />
                  </div>
                  <div className="createTicket__container--dropdown">
                    <AutoCompleteComponent
                      formik={formik}
                      name="subCategoryTicket"
                      label={i18n.t("Subcategory") as string}
                      options={subCategories.map((subCategory: any) => ({
                        label: subCategory?.category,
                        value: subCategory?.id,
                      }))}
                      textFieldPlaceHolder={values.id ? formik.values.subCategory : "Select Sub Category"}
                      multiple={true}
                    />
                  </div>
                  {/* <AutoCompleteComponent
                    formik={formik}
                    name="status"
                    label={i18n.t("Status") as string}
                    options={statuses}
                    textFieldPlaceHolder={"Select Status"}
                    renderCustomOptions={(option: any) => (
                      <div
                        className="createTicket__container--urgency-renderOption">
                        {option.icon}
                        <span style={{ color: option.color }}>
                          {option.label}
                        </span>
                      </div>
                    )}
                  /> */}
                  {/* <div className="createTicket__container--fileUpload">
                    <label htmlFor="selectStatus">
                      {i18n.t("Upload") as string}
                    </label>
                    <TicketUpload
                      name="imageUrl"
                      setFieldValue={setFieldValue}
                      dataTicket={[]}
                      removeImage={false}
                    />
                    {errors?.imageUrl && touched?.imageUrl && (
                      <span className="errorClass">
                        *
                        {typeof errors.imageUrl === "string"
                          ? errors.imageUrl
                          : ""}
                      </span>
                    )}
                  </div> */}

                  <div className="createTicket__container--dropdown">
                    <AutoCompleteComponent
                      formik={formik}
                      name="role"
                      label={i18n.t("Role") as string}
                      options={userRoles}
                      textFieldPlaceHolder={values.id ? values.role : "Select Role"}
                    />
                  </div>
                </div>

              </div>
              <div className="createTicket__container--createButton">
                <Button
                  type="submit"
                  width="100%"
                  text={values.id ? "Update" : "Create"}
                  customClassName="createTicket__container--createButton-button"
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default CreateUser;
