import axios from "../../utils/axios";
import { ENDPOINT } from "../../utils/endpoint";

export const getAllTickets = async (page: number, size: number, onSuccess: (data: any) => void, search: string,setLoading: (val: boolean) => void) => {
    setLoading(true)
    // @ts-ignore
    try {
        const response = await axios.get(ENDPOINT.GET_ALL_TICKETS.replace(":page", `${page}`).replace(":size", `${size}`).replace(":search", `${search}`));
        onSuccess(response?.data?.data)
    } catch (error) {
        throw error;
    } finally {
        setLoading(false);
    }
};