import axios from "../../utils/axios";
import { ENDPOINT } from "../../utils/endpoint";

export const getAllCategories = async () => {
    // @ts-ignore
    try {
        const response = await axios.get(ENDPOINT.GET_CATEGORIES);
        return response?.data;
    } catch (error) {
        console.log("Error fetching categories", error)
        throw error;
    }
};

export const getAllSubCategories = async () => {
    // @ts-ignore
    try {
        const response = await axios.get(ENDPOINT.GET_SUBCATEGORIES);
        return response?.data;
    } catch (error) {
        console.log("Error fetching categories", error)
        throw error;
    }
};

export const getCategoryById = async (id: any) => {
    try {
        const response = await axios.get(ENDPOINT.GET_CATEGORY_BYID.replace(":id", id));
        return response?.data;
    } catch (error) {
        console.error('Error fetching categories:', error);
        throw error;
    }
};