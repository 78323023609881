import React from "react";
import xModal from "../../../../src/assets/img/xModal.svg";

interface TicketHeaderComponentProps {
  title: string;
  handleClose: () => void;
  current?: any;
  handlePrevious?: any;
}

const TicketHeaderComponent: React.FC<TicketHeaderComponentProps> = ({
  title,
  handlePrevious,
  current,
  handleClose,
}) => (
  <div className="ticketHeader__header">
    <img
      // src={BackButton}
      alt=""
      onClick={handlePrevious}
      style={{ cursor: "pointer" }}
    />
    <span>{title}</span>
    <img
      src={xModal}
      alt=""
      onClick={handleClose}
      style={{ cursor: "pointer" }}
    />
  </div>
);

export default TicketHeaderComponent;
