import { Box } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";

const centerStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};
const rightStyle = {
  position: "absolute",
  top: 0,
  right: 0,
  height: "100vh",
  maxHeight: "100vh",
};
const positionStyles = {
  center: centerStyle,
  right: rightStyle,
};

const ModalComponent = (props: any) => {
  const {
    open,
    handleClose,
    className,
    width = "600",
    onScroll,
    customClassNames = "",
    positionModal = "center",
    hideBackdrop = false,
  } = props;
  let styles: React.CSSProperties = {
    backgroundColor: "background.paper",
    border: "none",
    boxShadow: "24",
    // padding: 4,
    outline: "none",
    // overflow: "auto",
    maxHeight: "85%",
    display: "block",
  };

  styles = { ...styles, ...(positionStyles as any)[positionModal] };
  return (
    <Modal
      hideBackdrop={hideBackdrop}
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableEnforceFocus={props.disableEnforceFocus}
    >
      <Box style={styles} onScroll={onScroll}>
        <div
          style={{
            backgroundColor: "white",
            width: `${width}px`,
            borderRadius: "10px",
          }}
          className={`popup_${className} ${customClassNames}`}
        >
          {props.children}
        </div>
      </Box>
    </Modal>
  );
};

export default ModalComponent;
