import axios from "../../utils/axios";
import { ENDPOINT } from "../../utils/endpoint";

export const getLoggedUser = async () => {
    // @ts-ignore
    try {
        const response = await axios.get(ENDPOINT.GET_LOGGED_USER);
        return response?.data;
    } catch (error) {
        console.log("Error fetching categories", error)
        throw error;
    }
};