import { ButtonComponentProps } from "../../types";
import Loader from "../Loader/index";

const ButtonComponent = (props: ButtonComponentProps) => {
  const {
    classNames = "primaryButton",
    disabled,
    onClick,
    type = "submit",
    buttonText = "Button",
    loading,
    newClass,
    hasIcon = false,
    Icon,
    iconProps,
  } = props;
  return (
    <button

      className={`${classNames} ${newClass || ""}`}
      disabled={disabled}
      onClick={onClick}
      type={type}
    >
      {!loading && hasIcon && Icon && <Icon {...iconProps} />}
      {!loading && buttonText}

      {loading && <Loader color="white" />}
    </button>
  );
};

export default ButtonComponent;
