import i18n from "../../i18n";
import TableCustomColumn from "./TableCustomColumn";

export const rows = [
  {
    nr: 12,
    username: "John Doe",
    phoneNumber: "+4917011711222",
    email: "johndoe@oneri.de",
    role: "Support",
    imageUrl: "",
    firstName: "John",
    lastName: "Doe",
    actions: ". . .",
  },
  {
    nr: 13,
    username: "Tony Stark",
    phoneNumber: "+4917011711230",
    email: "tonystark@oneri.de",
    role: "Support",
    imageUrl: "",
    firstName: "Tony",
    lastName: "Stark",
    actions: ". . .",
  },
  {
    nr: 14,
    username: "Tanja Zimmerman",
    phoneNumber: "+4917011711230",
    email: "tanja@oneri.de",
    role: "Support",
    imageUrl: "",
    firstName: "Tony",
    lastName: "Stark",
    actions: ". . .",
  },
];

export const ticketColumns = [
  {
    name: "Username",
    field: "usernameField",
    image: "imageField",
    formatter: ({ row }: any) => (
      <TableCustomColumn row={row} link="users" navLink={`${row.username}`} />
    ),
    headerStyle: {
      backgroundColor: "#fbfbfb",
      fontFamily: "Montserrat",
      fontSize: "1.3rem",
    },
    cellStyle: {
      backgroundColor: "#fbfbfb",
      marginRight: "190px",
      borderRightStyle: "solid",
      borderRightColor: "#f5f5f5",
      borderRightWidth: "1px",
    },
  },
  {
    name: i18n.t("PhoneNumber"),
    key: "phoneNumber",
    resizable: true,
    headerCellClass: "headerStyles",
  },
  {
    name: i18n.t("Email"),
    key: "email",
    resizable: true,
    headerCellClass: "headerStyles",
  },
  {
    name: i18n.t("Role"),
    key: "role",
    resizable: true,
    headerCellClass: "headerStyles",
  },
  // {
  //   name: i18n.t("Action"),
  //   key: "actions",
  //   resizable: true,
  //   headerCellClass: "headerStyles",
  // },
];
