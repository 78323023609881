import jwtDecode, { JwtPayload } from "jwt-decode";

export const getCurrentUser = (customToken?: string): JwtPayload | null => {
  try {
    const token: string | null = customToken ? customToken : localStorage.getItem("token") 

    if (token) {
      const decoded: JwtPayload = jwtDecode(token);

      // Check if `decoded` is defined and has the `exp` property
      if (
        decoded &&
        typeof decoded.exp === "number" &&
        new Date().getTime() / 1000 > decoded.exp
      ) {
        localStorage.removeItem("token");
        return null;
      }

      return decoded;
    }

    return null;
  } catch (error) {
    if (!window.location.pathname.includes("/auth")) {
      localStorage.removeItem("token");
    }
    return null;
  }
};
