import { useFormik } from "formik";
import moment from "moment";
import {
  urgency,
  validationSchema,
} from "../staticData";
import InputComponent from "../../../components/InputComponent";
import i18n from "../../../i18n";
import Button from "../../../components/Button";
import DraftText from "../../../components/DraftText";
import TicketHeaderComponent from "./headerTicketModal";
import { allCategoriesState, allSubCategoriesState, allTicketsState, otherUsersState, ticketModal, ticketSupportState } from "../../../utils/atoms";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  CreateTicketProps,
  TicketModalProps,
  TicketValueProps,
} from "../../../types";
import { DatePicker, DatePickerProps } from "antd";
import { handleTicket } from "../../../services/PostRequests/tickets/create";
import AutoCompleteComponent from "../../../components/AutoComplete";
import { retrieveParams } from "../../../utils/retrieveParams";

function CreateTicket(props: CreateTicketProps, id: any) {
  const { handleClose } = props;
  const initialValues = useRecoilValue(ticketSupportState);
  const categories = useRecoilValue(allCategoriesState);
  const subCategories = useRecoilValue(allSubCategoriesState);
  const setModalState = useSetRecoilState<TicketModalProps>(ticketModal);
  const [tickets, setTickets] = useRecoilState(allTicketsState)
  const otherUser = useRecoilValue(otherUsersState);
  // const apiKey = localStorage.getItem("apiKey");
  const {apiKey} = retrieveParams();



  const addTicketOnState = (values: any) => {
    const modifiedObject = {
      ...tickets,
      totalSize: tickets.totalSize + 1,
      data: [values, ...tickets.data],
    }
    setTickets(modifiedObject)
  }
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values: any) => {
      handleOnSubmit(values);
      setModalState(prevState => ({
        ...prevState,
        showModal: false,
      }));
    },
  });
  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    setFieldValue,
    touched,
    handleBlur,
  } = formik;


  const onPickerChange: DatePickerProps["onChange"] = (date, dateString) => {
    if (date) {
      const formattedDate = moment(date).valueOf();
      setFieldValue("dateOfFix",formattedDate);
    } else {
      setFieldValue("dateOfFix", "");
    }
  };

  const handleOnSubmit = (values: TicketValueProps) => {
    let payloadObj = {};
    let customOptions = {};
    if(apiKey) {
      payloadObj = {
        ...values,
        createdBy: otherUser?.sub,
      }
       customOptions = {
          headers: {
            "ROLE": otherUser?.role,
          }
      }
    } else {
      payloadObj = {...values};
    }
    handleTicket({ values: payloadObj, formik, onSuccess: addTicketOnState,customOptions });
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="createTicket__superContainer">
          <TicketHeaderComponent
            handleClose={handleClose}
            title={i18n.t("CreateNewTicket")}
          />
          <div className="createTicket__containers">
            <div className="createTicket__container">
              <div className="createTicket__container--title">
                <InputComponent
                  name="title"
                  type="text"
                  label={i18n.t("Title") as string}
                  errorClass="errorClass"
                  errors={errors?.title}
                  values={values?.title}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  touched={
                    typeof touched?.title === "boolean"
                      ? touched?.title
                      : undefined
                  }
                  newClass="secondStep__input"
                />
              </div>
              <div className="createTicket__container--description">
                <label>{i18n.t("Description") as string}</label>
                <DraftText
                  name="description"
                  value={values?.description}
                  options={["inline", "list", "textAlign", "link", "emoji"]}
                  onEditorStateChange={(editorState: any) => {
                    setFieldValue("description", editorState);
                  }}
                  wrapClassName="createTicket__container--description-wraper"
                  editClassName="createTicket__container--description-edit"
                  contentDraft="createTicket__container--description-draft"
                  errors={errors?.description}
                  touched={touched.description}
                  values={values?.description}
                  errorClass="errorClass"
                />
              </div>
              <AutoCompleteComponent
                formik={formik}
                name="categoryTicket"
                label={i18n.t("Category") as string}
                autoClassName="dropdown__customClass"
                options={categories?.map((category: any) => ({
                  label: category.label || "",
                  value: category.id || null,
                }))}
                // options={categoriesArray}
                textFieldPlaceHolder={"Select Category"}
                // renderCustomOptions={(option: any) => (
                //   <StatusBox
                //     statusData={statusData}
                //     row={option.label}
                //   />
                // )}
              />
              <AutoCompleteComponent
                formik={formik}
                name="subCategoryTicket"
                label={i18n.t("Subcategory") as string}
                autoClassName="dropdown__customClass"
                options={subCategories?.map((subCategory: any) => ({
                  label: subCategory.label || "",
                  value: subCategory.id || null,
                }))}
                textFieldPlaceHolder={"Select Sub-Category"}
              />
              <AutoCompleteComponent
                formik={formik}
                name="urgencyTicket"
                label={i18n.t("Urgency") as string}
                options={urgency}
                textFieldPlaceHolder={"Select Urgency"}
                autoClassName="dropdown__customClass"
                renderCustomOptions={(option: any) => (
                  <div className="createTicket__container--urgency-renderOption">
                    {option.icon}
                    <span style={{ color: option.color }}>{option.label}</span>
                  </div>
                )}
              />
              {/* <div className="createTicket__container--fileUpload">
                <label htmlFor="selectStatus">
                  {i18n.t("Upload") as string}
                </label>
                <TicketUpload
                  name="dataTicket"
                  setFieldValue={setFieldValue}
                  dataTicket={[]}
                  removeImage={false}
                />
                {errors?.dataTicket && touched?.dataTicket && (
                  <span className="errorClass">
                    *
                    {typeof errors.dataTicket === "string"
                      ? errors.dataTicket
                      : ""}
                  </span>
                )}
              </div> */}
              <div className="createTicket__container--dateContainer">
                <div className="createTicket__container--dateContainer-items">
                  <label>{i18n.t("DateOfFix") as string}</label>
                  <DatePicker onChange={onPickerChange} />
                  {errors?.dateOfFix && touched?.dateOfFix && (
                    <span className="errorClass">
                      *{typeof errors?.dateOfFix === "string"
                        ? errors?.dateOfFix
                        : ""}
                    </span>
                  )}
                </div>
              </div>
              <div className="createTicket__container--createButton">
                <Button
                  type="submit"
                  width="100%"
                  text={i18n.t("Create")}
                  customClassName="createTicket__container--createButton-button"
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default CreateTicket;
