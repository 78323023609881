import React from "react";
import CreateUser from "./createUser";
import ModalComponent from "../../../components/modal";
import { UserModalProps } from "../../../types";

const UserModal: React.FC<UserModalProps> = ({ showModal, handleClose, userData }) => {
  return (
    <ModalComponent
      open={showModal}
      handleClose={handleClose}
      customClassNames="ticket-modal"
      positionModal="right"
    >
      <div className="ticket__modalContainer">
        <CreateUser userData={userData} handleClose={handleClose} />
      </div>
    </ModalComponent>
  );
};

export default UserModal;
