import i18n from "../../i18n";

export const paginationItems = [
  {
    key: "30",
    label: "30",
  },
  {
    key: "50",
    label: "50",
  },
  {
    key: "100",
    label: "100",
  },
  {
    key: "200",
    label: "200",
  },
  {
    key: "500",
    label: "500",
  },
  {
    key: "1000",
    label: "1000",
  },
  {
    key: "2000",
    label: "2000",
  },
  {
    key: "3000",
    label: "3000",
  },
  {
    key: "All",
    label: i18n.t("All"),
  },
];
