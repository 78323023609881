import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import Header from "../../components/Header";
import { ButtonModal, SupportIcon } from "../../assets/svgComponents";
import DataGridTable from "../../components/DataGridTable";
import { dataOfStatus, ticketColumns } from "./columns";
import { Dropdown } from "antd";
import DeleteModal from "./modals/DeleteModal";
import { useEffect, useMemo, useState } from "react";
import i18n from "../../i18n";
import TicketModal from "../../Pages/Ticket-Managment/modals";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { allCategoriesState, allSubCategoriesState, allTicketsState, ticketModal, ticketSupportState } from "../../utils/atoms";
import { TicketModalProps } from "../../types";
import EditModal from "./modals/EditModal";
import { getAllTickets } from "../../services/GetRequests/getTickets";
import { category, urgency } from "./staticData";
import { deleteTicket } from "../../services/PostRequests/tickets/delete";
import { getAllCategories, getAllSubCategories } from "../../services/GetRequests/getCategories";
import { validateApiKey } from "../../services/GetRequests/validateApiKey";
import { useHistory } from "react-router-dom";
import { retrieveParams } from "../../utils/retrieveParams";
import loadingLottie from "../../assets/loadingLottie2.json";
import Lottie from "react-lottie";

const TicketSupportComponent = () => {
  const [deleteModal, setShowDeleteModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [search, setSearch] = useState("");
  const [size, setSize] = useState(0)
  const { t }: { t: TFunction } = useTranslation();
  const setTicketValues = useSetRecoilState(ticketSupportState);
  const history = useHistory();
  const [query, setQuery] = useState({
    client: null,
    user: null,
    date: null,
    status: null,
    urgency: null,
    category: null,
  })
  const [modalState, setModalState] =
    useRecoilState<TicketModalProps>(ticketModal);
  const [tickets, setTickets] = useRecoilState(allTicketsState)
  const [loading,setLoading] = useState(false);
  const [fullScreenLoader, setFullScreenLoader] = useState(false)
  const setCategories = useSetRecoilState(allCategoriesState);
  const setSubCategories = useSetRecoilState(allSubCategoriesState);
  const  {apiKey} = retrieveParams()
  const [reEditPage,setReEditPage] = useState(true);

  const { showModal } = modalState;
  const [openPopover, setOpenPopover] = useState(false);

  const handlePopoverOpen = (event: any) => {
    setOpenPopover(!openPopover);
  };

  const handleSearch = (e: any) => {
    editPage(1)
    setSearch(e.target.value);
  };
  const [ticketId, setTicketId] = useState(0);

  const addTicketsOnState = (data: any) => {
    const ticketsArray = tickets?.page > 1 ? [...tickets.data,...data.data] : data.data;
    setTickets({
      ...data,
      data: ticketsArray,
    })
  }
  const fetchData = async () => {
    await getAllTickets(tickets.page, tickets.size, addTicketsOnState, search, setLoading);
  };
  const getCategories = async () => {
    if (apiKey) {	
			try {
				const categories = await getAllCategories();
				setCategories(categories?.map((cat : any) => {
          return {
              id: cat.id,
              label: cat.category,
          }
        } ))
				const subcategories = await getAllSubCategories();
				setSubCategories(subcategories?.map((cat: any) => {
          return {
            id: cat.id,
            label: cat.category
          }
        }))
			} catch (error) {
				console.error('Error fetching data:', error);
			}
		} 
  }

  useEffect(() => {
    if(apiKey) {
      validateApiKey(history, fetchData, getCategories, setFullScreenLoader );
    } else {
      fetchData();

    }
  }, [search,apiKey,tickets.size,tickets.page]);

  const actionsFormatter = ({ row }: { row: any }) => {
    const items = [
      {
        key: "delete",
        name: "Delete",
        icon: (
          <div>
            <div className="ticket-managment-delete">
              <div>
                <ButtonModal />
              </div>
              <span>{t("DeleteTicket") as string}</span>
            </div>
          </div>
        ),
        onClick: () => {
          setTicketId(row.id);
          setShowDeleteModal(true);
        },
      },
    ];
    return (
      <Dropdown
        menu={{ items }}
        overlayClassName="popover__actions-wrapper"
        placement="bottomLeft"
        trigger={["click"]}
      >
        <div className="three-dots-icon">. . .</div>
      </Dropdown>
    );
  };

  const actions = {
    key: "actions",
    name: i18n.t("Action"),
    formatter: actionsFormatter,
    sortable: false,
    resizable: false,
    width: 50,
  };
  const handleOpenModal = () => {
    setModalState({ ...modalState, showModal: true });
  };
  const handleCloseModal = () => {
    setModalState({ ...modalState, showModal: false });
    setEditModal(false)
    setTicketValues({
      title: "",
      description: "",
      categoryTicket: "",
      subCategoryTicket: "",
      urgencyTicket: "",
      dateOfFix: null,
      ticketStatus: "PENDING",
    })
  };

  const handleFilter = (name: string, value: any) => {
    setQuery(prevState => {
      return {
        ...prevState,
        [name]: value
      }
    })
  }

  const filterInputs = [
    {
      inputType: "dropdown",
      name: "client",
      title: "Clients",
      options: [],
      values: query,
      onChange: handleFilter
    },
    {
      inputType: "dropdown",
      title: "User",
      options: [],
      values: query,
      name: "user",
      onChange: handleFilter
    },
    {
      inputType: "datePicker",
      title: "Date",
      onChange: handleFilter
    },
    {
      inputType: "dropdown",
      options: dataOfStatus,
      title: "Status",
      values: query,
      name: "status",
      onChange: handleFilter
    },
    {
      inputType: "dropdown",
      title: "Urgency level",
      options: urgency,
      values: query,
      name: "urgency",
      onChange: handleFilter
    },
    {
      inputType: "dropdown",
      title: "Category",
      options: category,
      values: query,
      name: "category",
      onChange: handleFilter
    },
  ]
  const handleRow = (row: any, cell: any) => {
    if (cell.key !== "actions") {
      const foundedTicket = tickets?.data?.find((ticket: any) => ticket?.id === row?.id);
      setTicketValues({
        ...foundedTicket,
        subCategoryTicket: foundedTicket?.subCategory,
        categoryTicket: foundedTicket?.category,
      })
      setEditModal(true);
    }
    setTicketId(row?.id);
  };

  const handleDeleteRow = async () => {
    if (tickets) {
      const updatedTickets = tickets.filter((ticket: any) => ticket.id !== ticketId);
      setTickets(updatedTickets);
      await deleteTicket({ ticketd: ticketId })   
      setShowDeleteModal(false);
    }
  };

  const columns = useMemo(() => {
        if(apiKey) {
          return ticketColumns;
        }
        return [...ticketColumns,actions];
  },[apiKey])
  const editPage = (page: number) => {
    setTickets({
      ...tickets,
      page,
    })
  }
  const editSize = (size : string | number) => {
    setTickets({
      ...tickets,
      size: size === "All" ? tickets.totalSize : +size,
    })
  }

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingLottie,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="ticketSupport__container">
      {fullScreenLoader ? 
        <div className="ticketSupport__container-loading">
        <Lottie
        options={defaultOptions}
        height={60}
        width={size < 1000 ? 100 : 150}
      />
      </div>
       : 
        <>
          <Header
            title={t("Support")}
            Icon={SupportIcon}
            handleSearch={handleSearch}
            name={""}
            isUsers={false}
            handleButton={handleOpenModal}
            searchValue={search}
            handleFilters={handlePopoverOpen}
            popoverContent={<div>...</div>}
            filterInputs={filterInputs}
          />
          <div className="ticket__wrapper">
            <DataGridTable
              columns={columns}
              data={tickets.data || []}
              pagination
              page={tickets.page}
              size={tickets.size}
              totalPages={tickets.totalPages}
              totalSize={tickets.totalSize}
              editPage={editPage}
              editSize={editSize}
              loading={loading}
              reEditPage={reEditPage}
              setReEditPage={setReEditPage}
              onRowClick={(idx, row, cell) => handleRow(row, cell)}
            />
          </div>
          {deleteModal && (
            <DeleteModal
              setShowDeleteModal={setShowDeleteModal}
              confirm={handleDeleteRow}
              open={deleteModal}
              handleClose={() => setShowDeleteModal(false)}
              word="Delete"
              newTitle={i18n.t("RemoveThisTicket")}
              deleteModal={true}
            />
          )}
          <TicketModal showModal={showModal} handleClose={handleCloseModal} />
          <EditModal
            showModal={editModal}
            ticketData={tickets}
            setTickets={setTickets}
            id={ticketId}
            handleClose={handleCloseModal}
          />
        </>
      }

    </div>
  );
};

export default TicketSupportComponent;
