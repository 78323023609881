import { ArrowDownIcon, ArrowUpIcon } from "../../assets/svgComponents";
import { useMemo, useState } from "react";
import DataGrid, { Row } from "react-data-grid";
import { useTranslation } from "react-i18next";
import Lottie from "react-lottie";
import Closed from "../../assets/img/expandPurpleDown.svg";
import Opened from "../../assets/img/expandPurpleUp.svg";
import Loader from "../Loader";
import loadingLottie from "../../assets/loadingLottie2.json";
import loadingLottieLong from "../../assets/ordersLoader.json";
import { paginationItems } from "./paginationItems";
import { sortWithDetailedRows } from "./sortDetailedRows";
import { DataGridTableProps } from "../../types";
import { TFunction } from "i18next";
import "react-data-grid/dist/react-data-grid.css";
import CustomPopover from "../CustomPopover";

const DataGridTable = (props: DataGridTableProps) => {
  const {
    columns,
    data,
    editSize,
    size = 5,
    totalSize = 5,
    loading,
    pagination,
    rowHeight = 44,
    height = 700,
    editPage,
    page = 1,
    totalPages = 1,
    onRowClick,
    toggle = false,
    rows,
    onRowsChange,
    className = "rdg-light",
    reEditPage,
    setReEditPage,
  } = props;
  const { t }: { t: TFunction } = useTranslation();
  function rowKeyGetter(row: any) {
    return row?.orderId || row?.id || row?.month || row?.uuid;
  }

  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [showTable, setShowTable] = useState(!toggle);
  const [sortColumns] = useState([]);
  const sortedRows = useMemo(() => {
    if (sortColumns.length === 0) return rows?.[0] ? rows : data;

    const { columnKey, direction }: any = sortColumns[0];

    let sortedRows = [...data];

    const rowsToSort = rows?.[0] ? [...rows] : [...data];

    rowsToSort.forEach((row) => {
      if (columnKey?.includes(".")) {
        const splittedKeys = columnKey.split(".");
        const [firstKey, secondKey] = splittedKeys;
        if (typeof row[firstKey]?.[secondKey] === "number") {
          sortedRows = sortedRows?.sort(
            (a, b) => a[firstKey]?.[secondKey] - b[firstKey]?.[secondKey]
          );
        } else {
          sortedRows = sortedRows?.sort((a, b) =>
            a[firstKey]?.[secondKey]?.localeCompare(b[firstKey]?.[secondKey], {
              sensitivity: "base",
            })
          );
        }
      } else if (typeof row[columnKey] === "string") {
        sortedRows = sortedRows?.sort((a, b) => {
          const numberA = +a[columnKey];
          const numberB = +b[columnKey];
          if (numberA && numberB) {
            return a[columnKey] - b[columnKey];
          }
          return a[columnKey]?.localeCompare(b[columnKey], {
            sensitivity: "base",
          });
        });
      } else if (typeof row[columnKey] === "number") {
        sortedRows = sortedRows?.sort((a, b) => a[columnKey] - b[columnKey]);
      }
    });
    if (rowsToSort.some((row) => row.type === "MASTER" && row.expanded)) {
      const updatedRows = sortWithDetailedRows(
        sortedRows,
        rowsToSort,
        direction
      );
      return updatedRows;
    }
    return direction === "DESC" ? sortedRows.reverse() : sortedRows;
  }, [data, rows, sortColumns]);

  const rowRenderer = (row: any) => (
    <div
      style={{ cursor: onRowClick && "pointer", overflowY: "auto" }}
      key={row.rowIdx}
      className="row-container"
    >
      <Row
        {...row}
        key={row.rowIdx}
        className={`${row?.row?.errors?.length ? "error" : ""} ${row?.row?.child ? "data__grid__row__child" : ""
          }`}
      />
    </div>
  );
  async function handleScrollPage(event: any) {
    event.preventDefault();
    if (
      !pagination ||
      loading ||
      !isAtBottom(event) ||
      page >= totalPages ||
      !reEditPage
    )
      return;
    if (editPage) {
      editPage(page + 1);
    }
  }
  function isAtBottom(e: any) {
    if(setReEditPage) {
      setReEditPage(true);
    }
    const {scrollTop,scrollHeight,clientHeight} = e.currentTarget;
    return (
      scrollTop >= scrollHeight - clientHeight
    );
  }
  const emptyRowsView = () => (
    <div className="empty-rows-view">
      {t<string>("NoData")}
    </div>
  );
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: size < 1000 ? loadingLottie : loadingLottieLong,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <>
      {toggle && (
        <div
          onClick={() => setShowTable(!showTable)}
          className="toggle-container"
        >
          <span>
            {t<string>(`Deactivated`)} ({totalSize})
          </span>
          <img alt="" src={showTable ? Closed : Opened} />
        </div>
      )}


      {showTable && (
        <>
          <DataGrid
            className={className}
            columns={columns}
            rows={sortedRows}
            rowKeyGetter={rowKeyGetter}
            rowRenderer={rowRenderer}
            rowHeight={rowHeight}
            style={{ height }}
            onRowsChange={onRowsChange}
            onScroll={handleScrollPage}
            emptyRowsRenderer={emptyRowsView}
            onRowClick={onRowClick && onRowClick}
          // sortColumns={sortColumns}
          // onSortColumnsChange={onSortColumnsChange}
          />
          {pagination && (
            <>
              {loading && (
                <div
                  className={`infinitscroll_loader ${page === 1 && "center"}`}
                >
                  {page === 1 ? (
                    <Lottie
                      options={defaultOptions}
                      height={60}
                      width={size < 1000 ? 60 : 150}
                    />
                  ) : (
                    <Loader />
                  )}
                </div>
              )}
              <div
                className="custom-pagination-wrapper"
                style={{
                  // position: loading && page !== 1 ? "relative" : "absolute",
                  bottom: loading && page !== 1 ? "40px" : "0",
                }}
              >
                <div className="custom-pagination">
                  {totalSize > 0 && (
                    <CustomPopover
                      menu={{
                        items: paginationItems,
                        onClick: (item: any) => {
                          if (editSize) {
                            editSize(item.key)
                            setDropdownVisible(false);
                          }
                        },
                      }}
                      customClass="popover__actions-wrapper"
                      placement="bottomLeft"
                      trigger={["click"]}
                      open={dropdownVisible}
                      handleVisibility={(show) => {
                        setDropdownVisible(show);
                      }}
                    >
                      <div className="three-dots-icon">
                        {`${size} ${t("Rows")}`}
                        {dropdownVisible ? <ArrowUpIcon /> : <ArrowDownIcon />}
                      </div>
                    </CustomPopover>
                  )}
                  <span className="custom-pagination-information">
                    {`${data?.filter((r) => r.type !== "DETAIL")?.length} ${t(
                      "from"
                    )} ${totalSize}`}
                  </span>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default DataGridTable;
