import { Dropdown } from "antd";
import { CustomPopoverProps } from "../../types";

const CustomPopover = (props: CustomPopoverProps) => {
  const {
    menu,
    customClass,
    placement = "bottomLeft",
    children,
    width = "max-content",
    minWidth = "120px",
    trigger = ["hover"],
    open,
    arrow = false,
    handleVisibility,
    disabled = false,
  } = props;
  return (
    <Dropdown
      overlayStyle={{ width, minWidth }}
      menu={menu}
      overlayClassName={customClass}
      disabled={disabled}
      placement={placement}
      destroyPopupOnHide={true}
      trigger={trigger}
      arrow={arrow}
      open={open}
      onOpenChange={handleVisibility}
    >
      {children}
    </Dropdown>
  );
};
export default CustomPopover;
