import { useEffect } from "react";
import { InputComponentProps } from "../../types";

const InputComponent = (props: InputComponentProps) => {
  const {
    errors,
    touched,
    handleBlur,
    handleChange,
    onKeyDown,
    values,
    name,
    placeholder = "",
    type = "text",
    errorClass,
    defaultValue,
    className,
    label,
    newClass,
    bottomBreak = false,
    min,
    disabled,
    hidden = false,
    setFieldValue,
    onlyPlaceholder,
    inputRef,
    onPaste = () => { },
    maxLength,
  } = props;

  useEffect(() => {
    if (hidden && setFieldValue) {
      setFieldValue(name, "");
    }
  }, [hidden, name, setFieldValue]);
  if (hidden) {
    return null;
  }
  return (
    <div
      className={`form_input ${errors && touched ? "error" : ""} ${newClass || ""
        }`}
    >
      {(label || placeholder) && (
        <p className="form_input__label">{label || placeholder}</p>
      )}
      <input
        ref={inputRef}
        name={name}
        onBlur={handleBlur}
        onChange={handleChange}
        onKeyDown={onKeyDown}
        placeholder={placeholder || onlyPlaceholder}
        type={type}
        value={values}
        style={{
          color: "#252525",
          borderRadius: "8px",
        }}
        defaultValue={defaultValue}
        className={className}
        min={min}
        disabled={disabled}
        onPaste={onPaste}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          {errors && touched && <span className={errorClass}>*{errors}</span>}
        </div>
        <div>
          {maxLength && (
            <p
              className="textarea_max_length"
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "5px",
                color: "#ddd",
              }}
            >
              {values?.length || 0}/{maxLength}
            </p>
          )}
        </div>
      </div>
      {bottomBreak && <div className="bottomBreak"></div>}
    </div>
  );
};

export default InputComponent;
