import axios from "axios";
import { retrieveParams } from "./retrieveParams";

const { REACT_APP_WEB_API_URL } = process.env;

// configure axios defaults
axios.defaults.baseURL = `${REACT_APP_WEB_API_URL}`;

export const setupAxios = () => {
  axios.interceptors.request.use((config) => {
    // config token as default per request
    const _token = window.localStorage.getItem("token");
    const _language = window.localStorage.getItem("language");
    const { apiKey, tenantId, otherUserEmail } = retrieveParams()

    if (tenantId && apiKey) {
      config.headers["ApiKey"] = apiKey;
      config.headers["TenantId"] = tenantId;
      config.headers["X-EMAIL"] = otherUserEmail;
    }
    config.headers["Accept-Language"] = `${_language || "de"}`;
    if (_token && !apiKey && !tenantId) {
      config.headers.Authorization = `Bearer ${_token}`;
    }
    return config;
  });

  // Add a response interceptor
  axios.interceptors.response.use(
    (response: any) => {
      /*
       * if error: "No permission!"
       * logout & go to login page
       */
      if (response && response.data && response.data.error) {
        if (response.data.error === "No permission!") {
          return;
        }
      }
      return response;
    },
    (error) => {
      const _error = { ...error };
      if (!_error.response) {
        return;
      }
      if (_error.response && _error.response.status === 401) {
        /*
         * Unauthorized Request
         * store.dispatch(userActions.logout());
         */
      } else if (_error.response && _error.response.status === 403) {
        /*
         * Forbidden Request
         * store.dispatch(forbiddenRequest());
         */
      } else if (_error.response && _error.response.status === 404) {
        /*
         * route not found
         * store.dispatch(erroredRequest());
         * history.push('/error/404');
         * store.dispatch(userActions.logout());
         */
      } else {
        /*
         * TODO FIX THIS
         * store.dispatch(userActions.logout());
         */
      }

      return Promise.reject(_error);
    }
  );
};

export default axios;
