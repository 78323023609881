import axios from "../../utils/axios";
import { ENDPOINT } from "../../utils/endpoint";

export const validateApiKey = async (history: any,fetchData: () => void,getCategories: () => void,setFullScreenLoader: (value: boolean) => void) => {
    setFullScreenLoader(true)
    try {
        await axios.get(ENDPOINT.VALIDATE_API_KEY);
        fetchData();
        getCategories();
    } catch (err) {
        history.push('/invalid/apiKey');
        return err;
    } finally {
        setFullScreenLoader(false)
    }
}