import { useState, useEffect } from 'react';
import './App.scss';
import Routes from './routes';
import Drawer from './components/Drawer';
import { useHistory, useLocation, useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { getLoggedUser } from './services/GetRequests/getLoggedUser';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { allCategoriesState, allSubCategoriesState, loggedUserState, otherUsersState } from './utils/atoms';
import { retrieveParams } from './utils/retrieveParams';

function App() {
	const [collapse, setCollapse] = useState(true);
	const [showDrawer] = useState(false);
	const [width, setWidth] = useState("calc(100% - 200px)");
	const [, setUser] = useRecoilState(loggedUserState)
	const location = useLocation();
	const history = useHistory();
	const pathName = location?.pathname?.includes("admin");
	const {apiKey,otherUser} = retrieveParams();

	const setCategories = useSetRecoilState(allCategoriesState);
	const setSubCategories = useSetRecoilState(allSubCategoriesState);
	const setOtherUser = useSetRecoilState(otherUsersState);


	const fetchLoggedUser = async () => {
		const loggedUser = await getLoggedUser();
		setUser(loggedUser)
		const categories = Object.entries(loggedUser.categoryMap)?.map((item) => {
			const [id, label] = item;
			return {
				id: +id,
				label: label as string
			}
		})
		const subCategory = Object.entries(loggedUser.subCategoryMap)?.map((item) => {
			const [id, label] = item;
			return {
				id: +id,
				label: label as string
			}
		})
		setCategories(categories)
		setSubCategories(subCategory)
	};

	useEffect(() => {
		if (otherUser) {
			// user is retrieved just need to store it on state
			setOtherUser(otherUser)
		}
	}, [otherUser]);


	const fetchDataBasedOnApiKey = async () => {
		// if (apiKey === null) {
			if (localStorage.getItem("token")) {
				fetchLoggedUser(); // Assuming this is another async function you need to handle similarly
			// }
		}
	};

	useEffect(() => {
		fetchDataBasedOnApiKey();
	}, [apiKey]);

	useEffect(() => {
		switch (true) {
			case !!apiKey:
				setWidth("100%");
				break;
			case collapse:
				if (pathName) {
					setWidth("calc(100% - 200px)");
				} else {
					setWidth("100%");
				}
				break;
			case !collapse:
				if (pathName) {
					setWidth("calc(100% - 100px)");
				} else {
					setWidth("100%");
				}
				break;
			default:
				setWidth("calc(100% - 200px)");
				break;
		}
	}, [pathName, collapse, apiKey]);
	return (
		<div className="parent">
			{pathName && !apiKey && (
				<Drawer
					setCollapse={setCollapse}
					collapse={collapse}
					newClass={!showDrawer ? "hideDrawer" : ""}
				/>
			)}
			<div
				className={`parent-container ${pathName ? "withDrawer" : ""}`}
				style={{
					width,
					transition: "0.5s ease-in-out",
					right: 0,
					position: "absolute",
					top: 0,
					height: "100%",
				}}
			>
				<Routes />
			</div>
			<ToastContainer autoClose={3000} />
		</div>
	);
}

export default App;
