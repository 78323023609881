import React from "react";
import { useTranslation } from "react-i18next";
import { NoApiKeyLogo } from "../../assets/svgComponents";

const NoAPiKey = () => {
    const {t} = useTranslation();
	return (
		<div className="no_apiKey--wrapper">
			<div className="no_apiKey--wrapper-inner">
				<NoApiKeyLogo />
				<span className="main__title">{t("apiKeyMissing")}</span>
				<span className="information">{t("apiKeyMissingInfo")}</span>
			</div>
		</div>
	);
}

export default NoAPiKey;