const TicketInfoComponent = (props: any) => {
  const { label, span } = props;
  return (
    <div className="ticket-info__content">
      <div className="ticket-info__content-label">
        <div>{label}</div>
      </div>
      <div className="ticket-info__content-span">
        <div>{span}</div>
      </div>
    </div>
  );
};

export default TicketInfoComponent;
