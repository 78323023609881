/* eslint-disable */
import { useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const DraftText = (props: any) => {
  const {
    value,
    onEditorStateChange,
    errorClass,
    errors,
    touched,
    wrapClassName,
    editClassName,
    contentDraft,
    options = [
      "inline",
      "blockType",
      "fontSize",
      "fontFamily",
      "list",
      "textAlign",
      "colorPicker",
      "link",
      "embedded",
      "emoji",
      "image",
      "remove",
      "history",
    ],
  } = props;
  const [uploadImg, setUploadImg] = useState([]);

  const getFileBase64 = (file: any, callback: any) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => callback(reader.result);
    reader.onerror = (error) => {};
  };

  const _uploadImageCallBack = (file: any) =>
    new Promise((resolve, reject) =>
      getFileBase64(file, (data: any) => resolve({ data: { link: data } }))
    );

  return (
    <div className={`draftContent ${contentDraft}`}>
      <Editor
        editorState={value}
        wrapperClassName={`demo-wrapper ${wrapClassName}`}
        editorClassName={`demo-editor ${editClassName}`}
        onEditorStateChange={onEditorStateChange}
        toolbar={{
          options,
          image: {
            uploadCallback: _uploadImageCallBack,
            previewImage: true,
          },
          // embedded: {
          //   embedCallback: (link: any) => {
          //   //   let detectedSrc: string = ''
          //       let detectedSrc: any;
          //     let slice = null
          //     slice = /(https.*?)"/.exec(link)
          //     if (!slice) {
          //       detectedSrc = /<iframe.*? src="(.*?)"/.exec(embed(link))
          //     }
          //     return slice
          //       ? slice?.[1]
          //       : (detectedSrc && detectedSrc[1]) || link
          //   },
          // },
        }}
      />
      {errors && touched && <span className={errorClass}>*{errors}</span>}
    </div>
  );
};

export default DraftText;
