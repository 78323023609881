import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";

const HtmlTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: "#fff",
    color: "#383838",
    // maxWidth: 220,
    padding: 12,
    fontSize: 14,
    fontFamily: "Montserrat",
    borderRadius: 6,
    border: "1px solid #70707055",
    left: -30,
  },
}))(Tooltip);

export default HtmlTooltip;
