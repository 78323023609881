import {
  ToastErrorComponent,
  ToastSuccesComponent,
} from "../../../components/ToastComponent/ToastComponent";
import { EditTicketValueProps } from "../../../types";
import axios from "axios";
import { ENDPOINT } from "../../../utils/endpoint";

export const editTicket = async ({
  payload,
  ticketData,
  setTickets,
}: EditTicketValueProps) => {
   await axios
    .put(ENDPOINT.EDIT_TICKET.replace(":id", `${payload.id}`), payload )
    .then((res: any) => {
      const updatedTickets = ticketData.map((item: any) => {
        if (item.id === res?.data?.id) {
          return res.data
        } else {
          return item
        }
      })
      setTickets(updatedTickets)
      ToastSuccesComponent("Ticket Edit Successfully");
    })
    .catch((err: any) => {
      if (err.response?.status === 401) {
        ToastErrorComponent("Something went wrong");
      }
    });
};


