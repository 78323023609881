type DirectionType = "DESC" | "ASC";

export const sortWithDetailedRows = (
  sortedRows: any,
  rowsToSort: any,
  direction: DirectionType
) => {
  const filteredRows = sortedRows?.filter(
    (item: any) => item?.type !== "DETAIL"
  );
  const rowsToIterate =
    direction === "DESC" ? [...filteredRows?.reverse()] : [...filteredRows];
  const updatedRows: any[] = [];
  rowsToIterate.forEach((item) => {
    if (item.expanded) {
      const detailRow = rowsToSort?.find((r: any) => r.parentId === item.id);
      updatedRows.push(item);
      updatedRows.push(detailRow);
    } else {
      updatedRows.push(item);
    }
  });
  return updatedRows;
};
