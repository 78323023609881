import Header from "../../components/Header";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { UsersIcon } from "../../assets/svgComponents";
import { useEffect, useState } from "react";
import DataGridTable from "../../components/DataGridTable";
import {  ticketColumns } from "./columns";
import i18n from "../../i18n";
import UserModal from "./modals";
import { useRecoilState,useSetRecoilState } from "recoil";
import { UserModalProps } from "../../types";
import { allCategoriesState, allSubCategoriesState, allUsersState, ticketModal, userSupportState } from "../../utils/atoms";
import { getAllUsers } from "../../services/GetRequests/getUsers";
import { getAllCategories, getAllSubCategories } from "../../services/GetRequests/getCategories";

const Users = () => {
  const { t }: { t: TFunction } = useTranslation();
  const [search, setSearch] = useState("");
  const [reEditPage, setReEditPage] = useState(true);
  const [users, setUsers] = useRecoilState(allUsersState)
  const [loading,setLoading] = useState(false);
  const setInitialValues = useSetRecoilState(userSupportState);
  const [categories, setCategories] = useRecoilState(allCategoriesState);
  const [subCategories, setSubCategories] = useRecoilState(allSubCategoriesState);
  const [selectedUser] = useState(null);
  const handleSearch = (e: any) => {
    setSearch(e.target.value);
  };

  const [modalState, setModalState] =
    useRecoilState<UserModalProps>(ticketModal);
  const { showModal } = modalState;

  const handleOpenModal = () => {
    setModalState({ ...modalState, showModal: true });
  };
  const handleCloseModal = () => {
    setModalState({ ...modalState, showModal: false });
    setInitialValues({
      id: null,
      firstName: "",
      lastName: "",
      password: "",
      email: "",
      phoneNumber: "",
      categoryTicket: [],
      subCategoryTicket: [],
      urgencyTicket: "",
      // imageUrl: null,
      role: "",
      status: "",
    })
  };

  const addUsersOnState = (data: any) => {
    const usersArray = users?.page > 1 ? [...users.data,...data.data] : data.data;
    setUsers({
      ...data,
      data: usersArray,
    });
  }

  const fetchData = async () => {
    await getAllUsers(users.page,users?.size, addUsersOnState, search,setLoading);
  };

  useEffect(() => {
    fetchData();
  }, [users.totalPages, users.size, search,users.page]);

  const fetchCategories = async () => {
    const data = await getAllCategories();
    const subData = await getAllSubCategories();
    setCategories(data);
    setSubCategories(subData)
  };

  useEffect(() => {
    fetchCategories();
  }, []);
  const editPage = (page: number) => {
    console.log(page);
    setUsers({
      ...users,
      page,
    })
  }
  const editSize = (size: string | number) => {
    setUsers({
      ...users,
      size: size === "All" ? users?.totalSize : +size,
    })
  }

  const handleRowClick = (rowData: any) => {
    const foundedCategories = categories?.filter((cat: any) => rowData?.category?.includes(cat.category) || rowData?.category?.includes(cat?.id));
    const foundedSubCategories = subCategories?.filter((cat: any) => rowData?.subCategory?.includes(cat.category) || rowData?.subCategory?.includes(cat?.id));
    setInitialValues({
      ...rowData,
      category: foundedCategories?.map((cat: any) => cat.category),
      subCategory: foundedSubCategories?.map((cat: any) => cat.category)
    })
    setModalState({ ...modalState, showModal: true });
  };

  return (
    <div className="user__container">
      <Header
        title={t("Users")}
        Icon={UsersIcon}
        handleSearch={handleSearch}
        searchValue={search}
        name={i18n.t("CreateUser")}
        isUsers={true}
        handleButton={handleOpenModal}

      />
      <div className="userTicket__wrapper">
        <DataGridTable
          columns={ticketColumns}
          data={users.data}
          pagination
          totalPages={users.totalPages}
          totalSize={users?.totalSize}
          page={users?.page}
          size={users?.size}
          editPage={editPage}
          reEditPage={reEditPage}
          setReEditPage={setReEditPage}
          editSize={editSize}
          loading={loading}
          onRowClick={(_, row) => handleRowClick(row)}
        />
      </div>
      <UserModal userData={selectedUser} showModal={showModal} handleClose={handleCloseModal} />
    </div>
  );
};

export default Users;
