import HtmlTooltip from "../HtmlTooltip/index";
import { ButtonProps } from "../../types";

function Button(props: ButtonProps) {
  const {
    icon,
    text,
    handleChange,
    handleButton,
    leftRounded = false,
    rightRounded = false,
    background = "linear-gradient(94.98deg, #6C1EB0 0%, #400077 100%)",
    disabled = false,
    type,
    color = "#fff",
    border = "none",
    borderRadius = "8px",
    width = "36px",
    height = "36px",
    minWidth = "100px",
    justifyContent = "center",
    customClassName,
    smallButtonWithTooltip = false,
    hideTooltip = false,
    textForSmallButton = null,
    placement = "bottom",
    svgIcon,
    style,
  } = props;
  if (smallButtonWithTooltip) {
    return (
      <HtmlTooltip
        title={<span>{text}</span>}
        placement={placement}
        disableHoverListener={hideTooltip}
      >
        <button
          // eslint-disable-next-line react/button-has-type
          type={type}
          disabled={disabled}
          style={{
            background,
            color,
            border,
            borderRadius,
            width,
            height,
            opacity: disabled ? "0.7" : "1",
            pointerEvents: disabled ? "none" : "auto",
            minWidth: "36px",
            justifyContent,
            ...style
          }}
          className={`createuserButton ${
            // eslint-disable-next-line no-nested-ternary
            leftRounded ? "leftRounded" : rightRounded ? "rightRounded" : ""
          } ${customClassName}`}
          onClick={handleChange}
        >
          {textForSmallButton || <img src={icon} alt="" />}
        </button>
      </HtmlTooltip>
    );
  }
  return (
    <>
      {type === "submit" ? (
        <button
          // eslint-disable-next-line react/button-has-type
          disabled={disabled}
          type={type}
          style={{
            background,
            color,
            border,
            borderRadius,
            width,
            height,
            opacity: disabled ? "0.7" : "1",
            pointerEvents: disabled ? "none" : "auto",
            minWidth,
            justifyContent,
            ...style
          }}
          className={`createuserButton ${
            // eslint-disable-next-line no-nested-ternary
            leftRounded ? "leftRounded" : rightRounded ? "rightRounded" : ""
          } ${customClassName}`}
          onClick={handleButton}
        >
          <img src={icon} alt="" />
          <span>{text}</span>
          {svgIcon && svgIcon}
        </button>
      ) : (
        <div
          style={{
            background,
            color,
            border,
            borderRadius,
            width,
            height,
            opacity: disabled ? "0.7" : "1",
            pointerEvents: disabled ? "none" : "auto",
            minWidth,
            justifyContent,
            ...style
          }}
          className={`createuserButton ${
            // eslint-disable-next-line no-nested-ternary
            leftRounded ? "leftRounded" : rightRounded ? "rightRounded" : ""
          } ${customClassName}`}
          onClick={handleChange}
        >
          <img src={icon} alt="" />
          <span>{text}</span>
          {svgIcon && svgIcon}
        </div>
      )}
    </>
  );
}

export default Button;
