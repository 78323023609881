import { ToastErrorComponent, ToastSuccesComponent } from "../../../components/ToastComponent/ToastComponent";
import { UserFunctionProps } from "../../../types";
import axios from "../../../utils/axios";
import { ENDPOINT } from "../../../utils/endpoint";

export const handleUser = async ({ values, formik, onSuccess }: UserFunctionProps) => {
    // @ts-ignore
    // @ts-ignore
    delete values?.id;
    await axios
        .post(ENDPOINT.CREATE_USER, { ...values, status: "ACTIVE" })
        .then((res: any) => {
            ToastSuccesComponent("User Created Successfully");
            if(onSuccess) {
                // add user on state
                 onSuccess(res);
            }
        })
        .catch((err: any) => {
            formik.setSubmitting(false);
            if (err.response?.status === 401) {
                ToastErrorComponent("Something went wrong")
            }
        });
};

export const handleEditUser = async ({ values, formik, onSuccess }: UserFunctionProps) => {
    if(values?.id) {
        await axios.put(
            ENDPOINT.EDIT_USER.replace(":id", `${values?.id}`), values
        ).then((res) => {
            ToastSuccesComponent("User Edited Successfully");
            if(onSuccess) {
                // update user 
                onSuccess()
            }
        }).catch((err: any) => {
            formik.setSubmitting(false);
            if (err.response?.status === 401) {
                ToastErrorComponent("Something went wrong")
            }
        })
    }
}

// export const handleUser = async ({ values, formik }: UserFunctionProps) => {
//     try {
//         const response = await axios.post(ENDPOINT.CREATE_USER, { ...values });
//         const newUser = response.data; // Assuming the response contains the newly created user data
//         ToastSuccesComponent("User Created Successfully");
//         return newUser; // Return the newly created user
//     } catch (err: any) {
//         formik.setSubmitting(false);
//         if (err.response?.status === 401) {
//             ToastErrorComponent("Something went wrong")
//         }
//         throw err; // Rethrow the error
//     }
// };
