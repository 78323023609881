import { LoginBigImage, OneriLogo } from "../../assets/svgComponents";
import { useRecoilState } from "recoil";
import { loginState } from "../../utils/atoms";
import { useFormik } from "formik";
import InputComponent from "../../components/InputComponent/index";
import ButtonComponent from "../../components/ButtonComponent";
import { validationSchema } from "./staticData";
import { useHistory } from "react-router-dom";
import { LoginValuesProps } from "../../types";
import { handleLogin } from "../../services/PostRequests/auth/auth";

const Login = () => {
  const [loginData] = useRecoilState(loginState);
  const history = useHistory();

  const formik = useFormik({
    initialValues: loginData,
    onSubmit: (values: LoginValuesProps) => {
      handleOnSubmit(values)
    },
    validationSchema: validationSchema,
  });

  const { values, touched, errors, handleChange, handleBlur, handleSubmit } =
    formik;

  const handleOnSubmit = (values: LoginValuesProps) => {
    handleLogin({ values, formik, history });
  };

  return (
    <div className="auth">
      <div className="left">
        <LoginBigImage />
      </div>
      <div className="middle">
        <form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <div className="company__logo">
            <OneriLogo />
          </div>
          <InputComponent
            name="email"
            placeholder={"Email"}
            type="email"
            errorClass="errorClass"
            errors={errors.email}
            values={values.email}
            handleBlur={handleBlur}
            handleChange={handleChange}
            touched={touched.email}
          />
          <InputComponent
            name="password"
            placeholder={"Password"}
            type="password"
            handleChange={handleChange}
            errorClass="errorClass"
            errors={errors.password}
            values={values.password}
            handleBlur={handleBlur}
            touched={touched.password}
          />
          <ButtonComponent
            buttonText={"Continue"}
            classNames="authBtn"
            type="submit"
          />
          <div className="login-bottom">
            <span
              className="forgot"
            // onClick={() => navigation(`/privacy-policy/${langVar || "de"}`)}
            >
              {"PrivacyPolicy"}
            </span>
            <span
              className="forgot"
            // onClick={() => navigation("/auth/forgot-password")}
            >
              {"ForgotPassowrd"}
            </span>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
