import React from "react";
import xModal from "../../../../src/assets/img/xModal.svg";

interface UserHeaderComponentProps {
  title: string;
  handleClose: () => void;
  current?: any;
  handlePrevious?: any;
  values?: any;
}

const UserHeaderComponent: React.FC<UserHeaderComponentProps> = ({
  title,
  handlePrevious,
  current,
  handleClose,
}) => (
  <div className="ticketHeader__header">
    <img
      // src={BackButton}
      alt=""
      onClick={handlePrevious}
      style={{ cursor: "pointer" }}
    />
    <span>{title}</span>
    <img
      src={xModal}
      alt=""
      onClick={() => handleClose()}
      style={{ cursor: "pointer" }}
    />
  </div>
);

export default UserHeaderComponent;
