import moment from "moment";
import {
  BillingErrorSmall,
  DeclinedIcon,
  FinishedIcon,
  MobileAppSmall,
  NotificationsIcon,
  PendingIcon,
  PriorityHigh,
  PriorityLow,
  PriorityMedium,
  PriorityNone,
  TechnicalErrorSmall,
  WebAppSmall,
  WorkProgressIcon,
} from "../../assets/svgComponents";
import StatusBox from "../../components/StatusBox";
import UrgencyLevel from "../../components/UrgencyLevel";
import i18n from "../../i18n";

export const statusData = {
  PENDING: { label: i18n.t("Pending"), icon: <PendingIcon />, color: "#FF8A00" },
  FINISHED: { label: i18n.t("Finished"), icon: <FinishedIcon />, color: "#6C1EB0" },
  DECLINED: { label: i18n.t("Declined"), icon: <DeclinedIcon />, color: "#D00A12" },
  WORK_IN_PROGRESS: { label: i18n.t("WorkInProgress"), icon: <WorkProgressIcon />, color: "#6DB070" },
  WEB_APP: {
    label: i18n.t("WebApp"),
    icon: <WebAppSmall />, 
    color: "#5673C2",
  },
  MOBILE_APP: {
    label: i18n.t("MobileApp"), 
    icon: <MobileAppSmall />,
    color: "#9A729E",
  },
  BILLING_ERROR: {
    label: i18n.t("BillingError"),
    icon: <BillingErrorSmall />,
    color: "#DA6D6D",
  },
  TECHNICAL_ERROR: {
    label: i18n.t("TechnicalError"),
    icon: <TechnicalErrorSmall />,
    color: "#6DB070",
  },
};

export const urgencyLevel = {
  NONE: {
    label: i18n.t("None"),
    // value: "NONE",
    color: "#B7B7B7",
    icon: <PriorityNone />,
  },
  LOW_PRIORITY: {
    label: i18n.t("LowPriority"),
    // value: "LOW_PRIORITY",
    color: "#31B073",
    icon: <PriorityLow />,
  },
  MEDIUM_PRIORITY: {
    label: i18n.t("MediumPriority"),
    // value: "MEDIUM_PRIORITY",
    color: "#EC8C3A",
    icon: <PriorityMedium />,
  },
  HIGH_PRIORITY: {
    label: i18n.t("HighPriority"),
    // value: "HIGH_PRIORITY",
    color: "#D00A12",
    icon: <PriorityHigh />,
  },
}
export const dataOfStatus = [
  {
    label: i18n.t("Pending"),
    icon: <PendingIcon />,
    color: "#FF8A00",
    value: "PENDING"
  },
  {
    label: i18n.t("Finished"),
    icon: <FinishedIcon />,
    color: "#6C1EB0",
    value: "FINISHED"
  },
  {
    label: i18n.t("Declined"),
    icon: <DeclinedIcon />,
    color: "#D00A12",
    value: "DECLINED"
  },
  {
    label: i18n.t("WorkInProgress"),
    icon: <WorkProgressIcon />,
    color: "#6DB070",
    value: "WORK_IN_PROGRESS"
  },
];
export const categoryStatus = {
  WEB_APP: {
    label: i18n.t("MobileApp"),
    icon: <MobileAppSmall />,
    color: "#5673C2",
  },
  MOBILE_APP: {
    label: i18n.t("WebApp"),
    icon: <WebAppSmall />,
    color: "#9A729E",
  },
  BILLING_ERROR: {
    label: i18n.t("BillingError"),
    icon: <BillingErrorSmall />,
    color: "#DA6D6D",
  },
  TECHNICAL_ERROR: {
    label: i18n.t("TechnicalError"),
    icon: <TechnicalErrorSmall />,
    color: "#6DB070",
  },
};

export const categoriesArray = Object.entries(categoryStatus)?.map((cat) => {
  const [key,value] = cat;
  return {
    label: value.label,
    value: key,
  }
})

export const rows = [
  {
    nr: 12,
    ticketId: "167059-228",
    status: "PENDING",
    createdBy: "Jon Snow",
    createdAt: "29.01.2024",
    urgency: "High Priority",
    client: "Maxprom",
    category: "Billing Error",
    subCategory: "Dashboard",
    dataTicket: [
      {
        name: "Screenshot 2024-01-09 at 1.56.20 PM.jpg",
        url: "image/jpg",
        size: "700",
      },
      {
        name: "Screenshot 2024-01-09 at 1.56.20 PM.png",
        url: "image/jpg",
        size: "700",
      },
    ],
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. ",
  },
];
// const formattedRows = sortedRows.map(row => ({
//   ...row,
//   createdAt: moment(row.updatedAt).format('YYYY-MM-DD'),
//   updatedAt: moment(row.updatedAt).format('YYYY-MM-DD'),
//   dateOfFix: moment(row.dateOfFix).format('YYYY-MM-DD'),
// }));
export const ticketColumns = [
  {
    name: <NotificationsIcon />,
    key: "nr",
    width: 20,
    headerCellClass: "notificationHeader",
  },
  {
    name: i18n.t("TicketId"),
    key: "id",
    resizabe: true,
    headerCellClass: "headerStyles",
  },
  {
    name: i18n.t("TicketTitle"),
    key: "title",
    resizabe: true,
    headerCellClass: "headerStyles",
  },
  {
    name: i18n.t("Status"),
    key: "ticketStatus",
    resizable: true,
    formatter: (row: any) => {
      return <StatusBox row={row?.row?.ticketStatus} statusData={statusData} />;
    },
    headerCellClass: "headerStyles",
  },
  {
    name: i18n.t("CreatedBy"),
    key: "createdBy",
    resizable: true,
    headerCellClass: "headerStyles",
  },
  {
    name: i18n.t("DateOfFix"),
    key: "dateOfFix",
    resizable: true,
    formatter: (row: any) => {
      return <div>{moment(row?.row.dateOfFix).format('YYYY-MM-DD')}</div>;
    },
    headerCellClass: "headerStyles",
  },
  {
    name: i18n.t("UrgencyLevel"),
    key: "urgencyTicket",
    resizable: true,
    headerCellClass: "headerStyles",
    formatter: (row: any) => {
      return <UrgencyLevel row={row?.row?.urgencyTicket} urgencyLevel={urgencyLevel} />;
    },
  },
  {
    name: i18n.t("Category"),
    key: "category",
    resizable: true,
    headerCellClass: "headerStyles",
    formatter: (row: any) => {
      return <StatusBox row={row?.row?.category} statusData={statusData} />;
    },
  },
  {
    name: i18n.t("UpdatedBy"),
    key: "updatedBy",
    resizable: true,
    headerCellClass: "headerStyles",
  },
];
