import * as yup from "yup";
import i18n from "../../i18n";
import {
  PriorityNone,
  PriorityLow,
  PriorityMedium,
  PriorityHigh,
  MobileApp,
  DesktopApp,
  BillingError,
  TechnicalError,
} from "../../assets/svgComponents";

export const HeaderTicketSupport = [
  { label: i18n.t("CostumizeTicketSupport"), key: "costumizeTicket" },
];

export const category = [
  {
    label: i18n.t("Mobile App"),
    value: "MOBILE_APP",
    icon: <MobileApp />,
  },
  {
    label: i18n.t("Desktop App"),
    value: "WEB_APP",
    icon: <DesktopApp />,
  },
  {
    label: i18n.t("Billing Error"),
    value: "BILLING_ERROR",
    icon: <BillingError />,
  },
  {
    label: i18n.t("Technical Error"),
    value: "TECHNICAL_ERROR",
    icon: <TechnicalError />,
  },
];
export const subcategory = [
  {
    label: i18n.t("Dashboard"),
    value: "DASHBOARD",
  },
  {
    label: i18n.t("Users"),
    value: "USERS",
  },
  {
    label: i18n.t("Contracts"),
    value: "CONTRACTS",

  },
  {
    label: i18n.t("Clients"),
    value: "CLIENTS",

  },
  {
    label: i18n.t("Locations"),
    value: "LOCATIONS",

  },
  {
    label: i18n.t("Trainings"),
    value: "TRAININGS",

  },
  {
    label: i18n.t("Chat"),
    value: "CHAT",

  },
];

export const urgency = [
  {
    label: i18n.t("None"),
    value: "NONE",
    color: "#B7B7B7",
    icon: <PriorityNone />,
  },
  {
    label: i18n.t("LowPriority"),
    value: "LOW_PRIORITY",
    color: "#31B073",
    icon: <PriorityLow />,
  },
  {
    label: i18n.t("MediumPriority"),
    value: "MEDIUM_PRIORITY",
    color: "#EC8C3A",
    icon: <PriorityMedium />,
  },
  {
    label: i18n.t("HighPriority"),
    value: "HIGH_PRIORITY",
    color: "#D00A12",
    icon: <PriorityHigh />,
  },
];


export const validationSchema = yup.object().shape({
  title: yup.string().required(i18n.t("TitleIsRequired")),
  description: yup.mixed().required(i18n.t("DescriptionIsRequired")),
  categoryTicket: yup
    .string()
    .required(i18n.t("CategoryIsRequired")),
  subCategoryTicket: yup
    .string()
    .required(i18n.t("SubCategoryIsRequired")),
  urgencyTicket: yup
    .string()
    .min(1, i18n.t("UrgencyIsRequired"))
    .required(i18n.t("UrgencyIsRequired")),
  dateOfFix: yup
    .number()
    .nullable()
    .label(i18n.t("dateOfFix"))

    .required(i18n.t(i18n.t("DateOfFixIsRequired"))),
  // dataTicket: yup
  //   .mixed()
  //   .required("File is required")
  //   .test("filesize", "file size is too large", (value: FileList | any) => {
  //     if (value && "length" in value) {
  //       for (let i = 0; i < value.length; i++) {
  //         const file = value[i] as File;
  //         if (file.size > 5242880) {
  //           return false;
  //         }
  //       }
  //     }
  //     return true;
  //   }),
});

export const filterInputs = (query: any, handleFilter: any, dataOfStatus: any) => [
  {
    inputType: "dropdown",
    name: "client",
    title: "Clients",
    options: [],
    values: query,
    onChange: handleFilter
  },
  {
    inputType: "dropdown",
    title: "User",
    options: [],
    values: query,
    name: "user",
    onChange: handleFilter
  },
  {
    inputType: "datePicker",
    title: "Date",
    onChange: handleFilter
  },
  {
    inputType: "dropdown",
    options: dataOfStatus,
    title: "Status",
    values: query,
    name: "status",
    onChange: handleFilter
  },
  {
    inputType: "dropdown",
    title: "Urgency level",
    options: urgency,
    values: query,
    name: "urgency",
    onChange: handleFilter
  },
  {
    inputType: "dropdown",
    title: "Category",
    options: category,
    values: query,
    name: "category",
    onChange: handleFilter
  },
]

