import React, { useEffect, useRef, useState } from "react";
import { StatusBoxProps, UrgencyLevelProps } from "../../types";

const UrgencyLevel = (props: UrgencyLevelProps) => {
    const { label, row, urgencyLevel, showText = true } = props;
    const [width, setWidth] = useState("");
    const parentRef = useRef<any>(null);
    useEffect(() => {
        if (parentRef.current) {
            const parentWidth = parentRef.current.offsetWidth;
            setWidth(`${parentWidth}px`);
        }
    }, []);
    return (
        <div className="container">
            <div
                className="status-container"
                ref={parentRef}
                style={{
                    border: `1px solid ${urgencyLevel?.[row]?.color}`,
                    position: "relative",
                }}
            >
                <div
                    className="status-icon-container"
                    style={{ borderRight: `1px solid ${urgencyLevel?.[row]?.color}` }}
                >
                    {urgencyLevel[row]?.icon}
                </div>
                {showText && <span className="status-text" style={{ color: urgencyLevel?.[row]?.color }}>{urgencyLevel?.[row]?.label}</span>}
            </div>
        </div>
    );
};

export default UrgencyLevel;
