import React from "react";
import ModalComponent from "../../../components/modal";
import { TicketModalProps } from "../../../types";
import CreateTicket from "./createTicket";

const TicketModal: React.FC<TicketModalProps> = ({
  showModal,
  handleClose,
}) => {
  return (
    <ModalComponent
      open={showModal}
      handleClose={handleClose}
      customClassNames="ticket-modal"
      positionModal="right"
    >
      <div className="ticket__modalContainer">
        <CreateTicket handleClose={handleClose} />
      </div>
    </ModalComponent>
  );
};

export default TicketModal;
