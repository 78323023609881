import { CloseIcon } from "../../assets/svgComponents";
import SearchIcon from "../../assets/img/SearchIcon.svg";
import filtersIcon from "../../assets/img/FilterIcon.svg";
import { BigSearchBarProps } from "../../types";
import Filters from "../Filters";

function BigSearchBar(props: BigSearchBarProps) {
  const {
    value,
    handleSearch,
    placeholder = "Search...",
    customClass,
    withFilters = false,
    handleFilters,
    hasCloseIcon = false,
    handleClose,
    style,
    replaceSearchIcon = false,
    widthSearchBar = "250px",
    disabled = false,
    popoverContent,
    filterInputs = []
  } = props;

  return (
    <div
      style={{
        width: withFilters ? "320px" : widthSearchBar,
        ...style,
      }}
      className={`big__search__bar__container ${customClass || ""}`}
    >
      {withFilters && (

        <Filters placement="bottomRight" content={popoverContent} filterInputs={filterInputs}>
          <div className="searchbar-filters" onClick={handleFilters}>
            <img src={filtersIcon} alt="" />
          </div>
        </Filters>
      )}
      <div
        className="search__input"
        style={{ width: withFilters ? "80%" : "100%" }}
      >
        <input
          value={value || ""}
          onChange={handleSearch}
          type="text"
          placeholder={placeholder}
          disabled={disabled}
        ></input>
        {!replaceSearchIcon ? (
          <img src={SearchIcon} alt="" />
        ) : value !== "" ? (
          <span className="clear_input_icon" onClick={handleClose}>
            <CloseIcon fill="#3b505d" width="11" height="12" />
          </span>
        ) : (
          <img src={SearchIcon} alt="" />
        )}
        {hasCloseIcon && (
          <span onClick={handleClose}>
            <CloseIcon fill="#3b505d" width="11" height="12" />
          </span>
        )}
      </div>
    </div>
  );
}

export default BigSearchBar;
