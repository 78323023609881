import { JwtPayload } from "jwt-decode";
import { getCurrentUser } from "./getCurrentUser";

interface parammsFnReturn {
    apiKey: string | null;
    otherUserEmail: string | null;
    tenantId: string | null;
}
export const retrieveParams = ()  => {
    const queryParams = new URLSearchParams(window.location.search);
    const apiKey = queryParams.get("apiKey") || null;
    const paramToken = queryParams.get("token") || null;
    let otherUserEmail = null;
    let otherUser = null;
    if(paramToken) {
        const otherUserObj: JwtPayload | null = getCurrentUser(paramToken);
        if (otherUserObj) {
            otherUserEmail = otherUserObj?.sub;
            otherUser = otherUserObj;
        }
    }
    const tenantId = queryParams.get("tenantId") || null;
    return { apiKey, otherUserEmail,tenantId,otherUser};
}